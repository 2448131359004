import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { customerApi } from "services/api/customerApi";
import moment from "moment";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";

moment.locale("fr");

const CustomerDetailsStat = ({ customerId }) => {
  const [customer, setCustomer] = useState({});
  const [open, setOpen] = useState(false);
  const { endDate, startDate } = useSelector((state) => state.period);

  const fetchData = async () => {
    try {
      const result = await customerApi.getCustomerDetails({
        customerId: parseInt(customerId),
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
      });

      if (result.errors || !result.data) {
        toast.error("Erreur dans la récupération des statistics");
        console.log(result.errors);
      } else {
        setCustomer(result.data.customerDetails);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [endDate, startDate, open]);

  return (
    <>
      <span
        onClick={() => setOpen(true)}
        className="underline underline-offset-2 cursor-pointer text-indigo-500"
      >
        Voir historique
      </span>
      {customer && customer.sponsored && customer.sponsored.length > 0 && (
        <>
          <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel
                      key={customerId}
                      className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-4/12 md:w-8/12 sm:p-6"
                    >
                        <div className="w-full h-5 text-center font-bold">
                           CLIENT { customer.lastName + " " + customer.firstName }
                        </div>
                      {customer.sponsored.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={`h-16 flex items-center justify-left ${
                              index % 2 === 0 ? "bg-white" : "bg-gray-50"
                            }`}
                          >
                            <span className="p-3 w-1/12"> Chèque n° <b>{item.checkNumber}</b></span>
                            <div className="flex-1 px-10">
                              {item.customer ? (
                                <>
                                  <p>
                                    <span className="font-semibold">
                                      {item.customer.lastName +
                                        " " +
                                        item.customer.firstName}
                                    </span>
                                    <br />
                                    <span className="text-gray-400 font-normal">
                                      {item.customer.address +
                                        " " +
                                        item.customer.completeAddress +
                                        " " +
                                        item.customer.zipCode}
                                    </span>
                                  </p>
                                </>
                              ) : (
                                <span className="p-3">
                                  <em>Aucun filleul renseigné</em>
                                </span>
                              )}
                            </div>
                            <div className="px-10">{item.amount + " €"}</div>
                            <div className="px-3 w-1/12 ">
                              {item.status === "RECEIVED" && (
                                <span className="text-green-500">
                                  Chèque encaissé
                                </span>
                              )}
                              {item.status === "REJECTED" && (
                                <span className="text-red-500">
                                  Chèque rejeté
                                </span>
                              )}
                              {item.status === "PENDING" && (
                                <span className="text-amber-500">
                                  Chèque en attente d'encaissement
                                </span>
                              )}
                            </div>
                            <div className="w-2/12 text-right px-3">
                              {" "}
                              <em>
    
                                Envoyé le {moment(item.editionAt).format("LL")}
                              </em>
                            </div>
                          </div>
                        );
                      })}

                      <div className="mt-5 sm:mt-6">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
                          onClick={() => setOpen(false)}
                        >
                          Revenir
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </>
      )}
    </>
  );
};

export default CustomerDetailsStat;
