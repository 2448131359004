import CenterStackedList from "components/center/CenterStackedList";
import React, { useState, useEffect } from "react";

const CenterList = () => {
  return (
    <>
      <div className=" mt-5 container mx-auto sm:px-6 lg:px-8">
        <CenterStackedList />
      </div>
    </>
  );
};

export default CenterList;
