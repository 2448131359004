import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import DashBoardLayout from "../components/layouts/DashBoardLayout";
import Loader from "../components/utils/Loader";

const UserRoute = ({ redirectPath = "/connexion" }) => {
  const { isAuth, loading } = useSelector((state) => state.auth);
  return loading ? (
    <>
      <Loader />
    </>
  ) : (
    <>
      {isAuth ? (
        <>
          <DashBoardLayout>
            <Outlet />
          </DashBoardLayout>
        </>
      ) : (
        <>
          <Navigate to={redirectPath} />
        </>
      )}
    </>
  );
};

export default UserRoute;
