export const SIGN_IN = `#graphql
  mutation signIn($login: String!, $password: String!) {
    signIn(login: $login, password: $password) {
      token
    }
  }
`;

export const VALID_TOKEN = `#graphql
  mutation IsValidToken($token: String!) {
  isValidToken(token: $token) {
    token
  }
}
`;
