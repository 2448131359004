import { EyeIcon } from "@heroicons/react/20/solid";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const CenterStackedList = () => {
  const { centerList } = useSelector((state) => state.center);
  return (
    <>
      {centerList && centerList.length > 0 && (
        <>
          <ul className="mt-10">
            {centerList.map((c, index) => {
              return (
                <li
                  key={index}
                  className={` ${
                    index % 2 === 0 ? "bg-gray-50" : "bg-white"
                  } flex justify-between gap-x-6 px-6 py-5`}
                >
                  <div className="flex min-w-0 gap-x-4">
                    <div className="min-w-0 flex-auto">
                      <p className="text-sm font-semibold leading-6 text-gray-900">
                        {c.name}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <NavLink
                      to={"/centres/" + c.id}
                      className="inline-flex ring-gray-300  ring-1 ring-inset hover:bg-gray-50 items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm border-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-maneo-500"
                    >
                      <EyeIcon className="h-5 w-5" />
                      Voir centre
                    </NavLink>
                  </div>
                </li>
              );
            })}
          </ul>
        </>
      )}
    </>
  );
};

export default CenterStackedList;
