import React, { useEffect } from "react";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { EyeIcon, XMarkIcon } from "@heroicons/react/24/outline";
import PdfView from "./PdfView";
import { GET_SETTING } from "services/graphql/setting";
import { graphql } from "services/api";
import { toast } from "react-toastify";

const OverlayPdf = () => {
  const [open, setOpen] = useState(false);
  const [setting, setSetting] = useState({});

  const fetchSetting = async () => {
    try {
      const result = await graphql({}, GET_SETTING);
      if (result.errors || !result.data) {
        toast.error(
          "Une erreur est survenu dans la récupération de vos paramètre"
        );
        console.log(result.errors);
      } else {
        if (!result.data.getSetting) {
          setSetting({ checkLine: 23.485, contentLine: 5.2 });
        } else {
          setSetting(result.data.getSetting);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSetting();
  }, []);
  return setting && (
    <>
      <button
        type="button"
        onClick={() => {
          setOpen(true);
        }}
        className=" flex items-center justify-center rounded-md border border-transparent bg-maneo-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-maneo-700 focus:outline-none focus:ring-2 focus:ring-maneo-500 focus:ring-offset-2 sm:w-auto mr-2"
      >
        Prévisualiser
        <EyeIcon className="ml-2 -mr-0.5 h-5 w-5" aria-hidden="true" />
      </button>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-7xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Pdf
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500  "
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        <PdfView  checkLine={setting.checkLine} contentLine={setting.contentLine}/>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default OverlayPdf;
