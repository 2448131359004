export const CREATE_OR_UPDATE_COUPON = `#graphql
    mutation CreateOrUpdateSponsorCoupon($sponsorListId: ID!, $couponId: ID, $sponsor: CustomerInput!, $customer: CustomerInput, $amount: Int!) {
        createOrUpdateSponsorCoupon(sponsorListId: $sponsorListId, couponId: $couponId, sponsor: $sponsor, customer: $customer, amount: $amount) {
            id
            status
            checkNumber
            amount
            customer {
            id
            firstName
            lastName
            address
            completeAddress
            zipCode
            }
            sponsor {
            id
            firstName
            lastName
            address
            completeAddress
            zipCode
            }
        }
    }
`;

export const PENDING_STATUS = `#graphql
    mutation UpdatePendingStatus($sponsorCouponId: ID!) {
        updatePendingStatus(sponsorCouponId: $sponsorCouponId) {
            id
            status
        }
    }
`;

export const REJECTED_STATUS = `#graphql
    mutation UpdateRejectedStatus($sponsorCouponId: ID!) {
        updateRejectedStatus(sponsorCouponId: $sponsorCouponId) {
            id
            status
        }
    }
`;

export const RECEIVED_STATUS = `#graphql
    mutation UpdateReceivedStatus($sponsorCouponId: ID!) {
        updateReceivedStatus(sponsorCouponId: $sponsorCouponId) {
            id
            status
        }
    }
`;

export const DELETE_SPONSOR_COUPON = `#graphql
    mutation Mutation($sponsorCouponId: ID!) {
        deleteSponsorCoupon(sponsorCouponId: $sponsorCouponId)
    }
`;

export const EXPORT_SPONSOR_COUPONS = `#graphql
    query Query($centerId: ID!, $startDate: Date!, $endDate: Date!) {
        getSponsorCoupons(centerId: $centerId, startDate: $startDate, endDate: $endDate) {
            amount
            checkNumber
            editionAt
            sponsor {
                id
                gender
                firstName
                lastName
                address
                completeAddress
                zipCode
            }
        }
    }
`;