import React, { useEffect, Fragment, useState } from "react";
import BackButton from "../../../components/utils/BackButton";
import moment from "moment";
import SponsorListInfo from "../../../components/sponsor/list/SponsorListInfo";
import SponsorStackedCoupon from "../../../components/sponsor/coupon/SponsorStackedCoupon";
import SponsorCouponForm from "../../../components/forms/SponsorCouponForm";
import CustomerSuggestion from "../../../components/customer/CustomerSuggestion";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { sponsorListApi } from "services/api/sponsorListApi";
import { updateCurrentSponsorList } from "features/sponsor/list/sponsorListSlice";
import Loader from "components/utils/Loader";
import { toast } from "react-toastify";
moment.locale("fr");

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SponsorFile = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { currentSponsorList, sponsorList } = useSelector(
    (state) => state.sponsorList
  );

  const fetchSponsorList = async () => {
    try {
      const result = await sponsorListApi.getSponsorList(params.id);
      if (result.errors || !result.data) {
        toast.error("Une erreur est survenu dans la récupération du fichier");
        console.log(result.errors);
      } else {
        dispatch(updateCurrentSponsorList(result.data.getSponsorList));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!currentSponsorList) {
      fetchSponsorList();
    }
  }, []);

  return currentSponsorList ? (
    <>
      <div className=" mt-5 container mx-auto sm:px-6 lg:px-8">
        <BackButton previousUrl={"/parrainages"} />
        <SponsorListInfo />
        <div className="grid grid-cols-12 gap-4">
          <div
            className={`${
              currentSponsorList.isLocked ? "hidden" : "col-span-4"
            }`}
          >
            <SponsorCouponForm />
          </div>
          <div
            className={`${
              currentSponsorList.isLocked ? "col-span-12" : "col-span-8"
            }`}
          >
            {!currentSponsorList.isLocked && <CustomerSuggestion />}
            <SponsorStackedCoupon />
          </div>
        </div>
      </div>
      <div className="container mx-auto flex w-full  items-start gap-x-8 px-4 py-10 sm:px-6 lg:px-8">
        {/* <aside className="sticky top-8 hidden w-44 shrink-0 lg:block"></aside> */}

        <main className="flex-1"></main>

        <aside className="sticky top-8 hidden w-1/3 shrink-0 xl:block"></aside>
      </div>
    </>
  ) : (
    <>
      <Loader />
    </>
  );
};

export default SponsorFile;
