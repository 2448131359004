// features/auth/authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import decode from "jwt-decode";
import { isValidToken, signIn } from "./authAction";

const initUserInfo = () => {
  const token = localStorage.getItem("token") ?? null;
  
  return token ? decode(token) : null
}

const initialState = {
  loading: false,
  userInfo: initUserInfo(), // for user object
  userToken: localStorage.getItem("token") ?? null, // for storing the JWT
  error: null,
  isAuth: localStorage.getItem("token") ? true : false, // for monitoring the registration process.
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    checkAuth: (state, action) => {
      const token = localStorage.getItem("token") ?? null;
      if (token) {
        state.userInfo = decode(token);
        state.isAuth = true;
        state.userToken = token;
      }
    },
    logout: (state) => {
      localStorage.removeItem("token");
      state.isAuth = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, { payload }) => {
        state.userInfo = decode(payload.data.signIn.token);
        state.isAuth = true;
        state.userToken = payload.data.signIn.token;
        state.loading = false;
        localStorage.setItem("token", state.userToken);
      })
      .addCase(signIn.rejected, (state, action) => {})
      .addCase(isValidToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(isValidToken.fulfilled, (state, { payload }) => {
        state.userInfo = decode(payload.data.isValidToken.token);
        state.isAuth = true;
        state.userToken = payload.data.isValidToken.token;
        state.loading = false;
        localStorage.setItem("token", state.userToken);
      })
      .addCase(isValidToken.rejected, (state) => {
        state.token = null;
        state.userInfo = null
        state.isAuth = false
      });
  },
});
export const { checkAuth, logout } = authSlice.actions;
