import React, { useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getCenters } from "../../features/center/centerAction";
import { switchCenter } from "../../features/center/centerSlice";
import { getSponsorListByCenter } from "features/sponsor/list/sponsorListAction";


const CenterSelector = () => {
  const { loading, centerList, current } = useSelector((state) => state.center);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCenters());
  }, []);

  useEffect(() => {
    if(current) {
      dispatch(getSponsorListByCenter(parseInt(current.id)));
    }
    
  }, [current]);
  
  return (
    <>
      <Select
        isLoading={loading}
        loadingMessage={() => "Chargement des données"}
        placeholder={"Selectionnez un centre"}
        noOptionsMessage={() => "Aucun centre a été retrouvé"}
        onChange={(event) => dispatch(switchCenter(event))}
        value={current}
        options={
          centerList.map((center) => {
            return { label: center.name, value: center.id };
          }) ?? []
        }
      />
    </>
  );
};

export default CenterSelector;
