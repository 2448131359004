import axios from "axios";
import { authApi } from "./authApi";
import { centerApi } from "./centerApi";
import { sponsorListApi } from "./sponsorListApi";
import { couponApi } from "./couponApi";
import { customerApi } from "./customerApi";
import { checkApi } from "./checkbundleApi";
import { SERVER_URL, headers } from "../../app/config";

export const callApi = async (url, query, object, headers) => {
  try {
    const { data } = await axios.post(
      url,
      {
        query: query,
        variables: object,
      },
      { headers: headers }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const graphql = async (data, route) => {
  try {
    return await callApi(
      SERVER_URL,
      route,
      data,
      {
        ...headers,
        authorization: localStorage.getItem("token"),
      }
    );
  } catch (error) {
    
  }
}

export const api = { authApi, centerApi, sponsorListApi, couponApi, customerApi, checkApi };
