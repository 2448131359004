import { callApi } from ".";
import { SERVER_URL, headers } from "../../app/config";
import { GET_STATS_BY_CENTER } from "services/graphql/statistic";

const getStatByCenter = async (data) => {
  try {
    return await callApi(SERVER_URL, GET_STATS_BY_CENTER, data, {
      ...headers,
      authorization: localStorage.getItem("token"),
    });
  } catch (error) {
    console.log(error.message);
  }
};

export const statApi = { getStatByCenter };