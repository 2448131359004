import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { statApi } from "services/api/statApi";
import moment from "moment";
import { useState } from "react";
import { toast } from "react-toastify";
import Loader from "components/utils/Loader";
import {
  ChevronRightIcon,
  EllipsisVerticalIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import CenterDateRangePicker from "components/center/CenterDateRangePicker";
import CustomerDetailsStat from "components/customer/CustomerDetailsStat";

moment.locale("fr");

const CustomerList = () => {
  const dispatch = useDispatch();
  const { endDate, startDate } = useSelector((state) => state.period);
  const { current } = useSelector((state) => state.center);
  const [statList, setStatList] = useState([]);
  const [topThree, setTopThree] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await statApi.getStatByCenter({
        centerId: parseInt(current.value),
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
      });
      if (result.errors || !result.data) {
        toast.error("Erreur dans la récupération des statistics");
        console.log(result.errors);
      } else {
        if (result.data.getCouponsStatsByCenter.length > 3) {
          setTopThree(result.data.getCouponsStatsByCenter.slice(0, 3));
          setStatList(result.data.getCouponsStatsByCenter.slice(3));
        } else {
          setTopThree(result.data.getCouponsStatsByCenter);
          setStatList([]);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if(current && startDate && endDate) {
      fetchData();
    }
    
  }, []);

  useEffect(() => {
    if(current && startDate && endDate) {
      fetchData();
    }
  }, [current, endDate, startDate]);

  const filteredList = statList.filter((c) => {
    return (
      c.firstname.toUpperCase().includes(search.toUpperCase()) ||
      c.lastname.toUpperCase().includes(search.toUpperCase())
    );
  });
  return (
    <>
      <div className="flex justify-between">
        <CenterDateRangePicker />
        <div className="relative mt-5 rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            type="text"
            name="search"
            id="search"
            className="block w-full h-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-maneo-700 sm:text-sm sm:leading-6"
            placeholder="Rechercher client"
            onChange={(e) => setSearch(e.currentTarget.value)}
          />
        </div>
      </div>
      {loading ? (
        <>
          <Loader />
        </>
      ) : topThree.length > 0 ? (
        <>
          <ul className="mt-10 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3">
            {topThree.map((c, index) => {
              const color = ["bg-green-500", "bg-amber-500", "bg-blue-500"];
              return (
                <li
                  key={index}
                  className="col-span-1 flex rounded-md shadow-sm"
                >
                  <div
                    className={`${color[index]} flex w-20 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white`}
                  >
                    {c.totalcoupon}
                  </div>
                  <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                    <div className="flex-1 truncate px-4 py-2 text-sm">
                      <span className="font-medium text-gray-900 hover:text-gray-600">
                        {c.lastname + " " + c.firstname}
                      </span>
                      <p className="text-gray-500">
                        {c.address + " " + c.completeaddress}
                        <br />
                        {c.zipcode}
                      </p>
                    </div>
                    <div className="flex-shrink-0 pr-2">
                      <CustomerDetailsStat customerId={c.idcustomer} />
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>

          {filteredList && filteredList.length > 0 && (
            <ul className="mt-10">
              {filteredList.map((c, index) => {
                return (
                  <li
                    key={index}
                    className={` ${
                      index % 2 === 0 ? "bg-gray-50" : "bg-white"
                    } flex justify-between gap-x-6 px-6 py-5`}
                  >
                    <div className="flex min-w-0 gap-x-4">
                      <div
                        className="h-12 w-12 flex items-center justify-center bg-maneo-500 rounded-md text-white"
                        src={c.totalcoupon}
                      >
                        {c.totalcoupon}
                      </div>
                      <div className="min-w-0 flex-auto">
                        <p className="text-sm font-semibold leading-6 text-gray-900">
                          {c.lastname + " " + c.firstname}
                        </p>
                        <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                          {c.address +
                            " " +
                            c.completeaddress +
                            " " +
                            c.zipcode}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <CustomerDetailsStat customerId={c.idcustomer} />
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </>
      ) : (
        <>
          <div className="h-1/2 flex items-center justify-center text-gray-800 itali mt-10">
            <span>
              <em>Aucunes données trouvées</em>
            </span>
          </div>
        </>
      )}
    </>
  );
};

export default CustomerList;
