import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getSponsorListByCenter } from "../../../features/sponsor/list/sponsorListAction";
import Loader from "../../utils/Loader";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon, EyeIcon } from "@heroicons/react/20/solid";
import { NavLink } from "react-router-dom";
import { graphql } from "services/api";
import { LOCK_SPONSOR_LIST } from "services/graphql/sponsorlist";
import { toast } from "react-toastify";

moment.locale("fr");

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const SponsorStackedList = () => {
  const { loading, sponsorList } = useSelector((state) => state.sponsorList);
  const { endDate, startDate, selectedYear } = useSelector(
    (state) => state.period
  );
  const { current } = useSelector((state) => state.center);
  const [reload, setReload] = useState(false);
  const lockFile = async (id) => {
    try {
      const result = await graphql({ sponsorListId: id }, LOCK_SPONSOR_LIST);
      if (result.errors || !result.data) {
        toast.error("Une erreur est survenu dans du vérouillage du fichier.");
      } else {
        setReload(true);
      }
    } catch (error) {}
  };
  const handleLock = (id) => {
    lockFile(id);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getSponsorListByCenter({
        centerId: parseInt(current.value),
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
      })
    );
  }, []);

  useEffect(() => {
    if (reload) {
      dispatch(
        getSponsorListByCenter({
          centerId: parseInt(current.value),
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
        })
      );
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    dispatch(
      getSponsorListByCenter({
        centerId: parseInt(current.value),
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
      })
    );
  }, [current, endDate, startDate]);

  const filteredList = sponsorList.filter(
    (list) => moment(list.editionAt).format("YYYY") === selectedYear.toString()
  );
  return loading ? (
    <>
      <Loader />
    </>
  ) : filteredList.length > 0 ? (
    <>
      <ul role="list" className="divide-y divide-gray-100">
        {filteredList.map((file, index) => (
          <li
            key={index}
            className="flex items-center justify-between gap-x-6 py-5"
          >
            <div className="min-w-0">
              <div className="flex items-start gap-x-3">
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  {file.name}
                </p>
                <p
                  className={classNames(
                    file.isLocked
                      ? "text-red-700 bg-red-50 ring-red-600/20"
                      : "text-green-700 bg-green-50 ring-green-600/20",
                    "rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
                  )}
                >
                  {file.isLocked ? "Verouillé" : "Ouvert"}
                </p>
              </div>
              <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                <p className="whitespace-nowrap">
                  Créé le {moment(file.createdAt).format("LL")}
                </p>
                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                  <circle cx={1} cy={1} r={1} />
                </svg>
                <p className="truncate">
                  {" "}
                  Publication le {moment(file.editionAt).format("LL")}
                </p>
              </div>
            </div>
            <div className="flex flex-none items-center gap-x-4">
              <NavLink
                to={"/parrainages/fichier/" + file.id}
                className="inline-flex ring-gray-300  ring-1 ring-inset hover:bg-gray-50 items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm border-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-maneo-500"
              >
                <EyeIcon className="h-5 w-5" />
                Voir fichier
              </NavLink>
              <Menu as="div" className="relative flex-none">
                <Menu.Button className="-m-2.5 p-2.5 text-gray-500 hover:text-gray-900">
                  <span className="sr-only">Open options</span>
                  <EllipsisVerticalIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    <Menu.Item>
                      {({ active }) => (
                        <NavLink
                          href="#"
                          className={classNames(
                            active ? "bg-gray-50" : "",
                            "block px-3 py-1 text-sm leading-6 text-gray-900"
                          )}
                        >
                          Voir PDF
                        </NavLink>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          href="#"
                          onClick={() => handleLock(file.id)}
                          className={classNames(
                            active ? "bg-gray-50" : "",
                            "block px-3 py-1 text-sm text-left leading-6 text-red-600 w-full"
                          )}
                        >
                          Verouiller
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </li>
        ))}
      </ul>
    </>
  ) : (
    <>
      <div className="h-1/2 flex items-center justify-center text-gray-800 itali mt-10">
        <span>
          <em>Aucunes données trouvées</em>
        </span>
      </div>
    </>
  );
};

export default SponsorStackedList;
