import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import { authSlice } from "../features/auth/authSlice";
import { centerSlice } from "../features/center/centerSlice";
import { periodSlice } from "../features/period/periodSlice";
import { sponsorListSlice } from "../features/sponsor/list/sponsorListSlice";
import { sponsorCouponSlice } from "../features/sponsor/coupon/sponsorCouponSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authSlice.reducer,
    center: centerSlice.reducer,
    period: periodSlice.reducer,
    sponsorList: sponsorListSlice.reducer,
    sponsorCoupon: sponsorCouponSlice.reducer
  },
});
