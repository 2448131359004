import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowPathIcon,
  Cog6ToothIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import { toast } from "react-toastify";
import Loader from "components/utils/Loader";
import { api, graphql } from "services/api";
import { CURRENT_CHECK_NUMBER } from "services/graphql/center";
moment.locale("fr");

const ModalUpdateCurrentCheck = ({ centerId }) => {
  const [current, setCurrent] = useState();
  const [currentCheck, setCurrentCheck] = useState(0);
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false);

  const handleChange = ({ currentTarget }) => {
    if (isNaN(currentTarget.value)) setCurrent(0);
    if (!isNaN(currentTarget.value)) setCurrent(currentTarget.value);
  };

  const update = async () => {
    try {
      const result = await api.checkApi.updateCurrentCheck({
        current: parseInt(current),
        centerId: parseInt(centerId),
      });
      if (result.errors || !result.data) {
        toast.error(
          "Une erreur est survenu dans la modification du numéro de chèque"
        );
        console.log(result.errors);
      } else {
        toast.success("Modification réussie");
      }
    } catch (error) {
      toast.error(
        "Une erreur s'est  produite lors de la mise à jour du chèque courant"
      );
    }
  };

  const fetchCurrentCheck = async () => {
    const result = await graphql({ centerId: centerId }, CURRENT_CHECK_NUMBER);
    if (result.errors || !result.data) {
      toast.error(
        "Une erreur est survenu dans la récupération du numéro de chèque en cours."
      );
    } else {
      setCurrentCheck(result.data.getCurrentCheckNumber);
    }
  };

  const handleUpdateCurrent = () => {
    if (centerId) {
      fetchCurrentCheck();
    }
  };

  useEffect(() => {
    if (centerId) {
      fetchCurrentCheck();
    }
  }, []);

  const handleSubmit = () => {
    setLoad(true);
    setTimeout(() => {
      update();
      setLoad(false);
    }, 500);
  };

  return (
    <>
      <div className="flex items-center">
        <button
          type="button"
          onClick={() => setOpen(!open)}
          className="mr-2 inline-flex items-center gap-x-1.5 rounded-md bg-maneo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-maneo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-maneo-500"
        >
          <Cog6ToothIcon className="h-5 w-5" />{" "}
          <span>{"N° " + currentCheck ?? 0}</span>
        </button>
        <button>
          <ArrowPathIcon className="h-5 w-5" onClick={handleUpdateCurrent}/>
        </button>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                      <PencilIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Modifier le chèque courrant
                      </Dialog.Title>
                      <div className="mt-2">
                        <form action="" onSubmit={handleSubmit}>
                          <div className="w-full sm:max-w-xs flex align-middle">
                            <label htmlFor="current" className="sr-only">
                              Chèque en cours
                            </label>
                            <input
                              type="number"
                              name="current"
                              id="current"
                              value={current}
                              onChange={handleChange}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-maneo-500 sm:text-sm sm:leading-6 mr-2"
                              placeholder="Entrer numéro"
                            />
                            {load && <Loader />}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                      onClick={handleSubmit}
                    >
                      Enregistrer
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                    >
                      Annuler
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default ModalUpdateCurrentCheck;
