import React, { useState, useEffect } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { centerApi } from "services/api/centerApi";

const initialState = {
  centerId: null,
  min: 0,
  max: 0,
}
const CenterCheckForm = ({ centerId, setRefresh }) => {
  const [newCheckBundle, setNewCheckBundle] = useState(initialState)
  const handleSubmit = (event) => {
    event.preventDefault();
    if(newCheckBundle.min > 0 && newCheckBundle.max > 0) {
      create();
    } else {
      toast.error('Les numéros de chèque doivent être supérieur à zéro');
    }
  };

  const create = async () => {
    try {
      const result = await centerApi.addNewCheckBundle(newCheckBundle);
      if (result.errors || !result.data) {
        toast.error("Erreur dans la création du lot de chèque");
        console.log(result.errors);
      } else {
        setRefresh(true)
        setNewCheckBundle(initialState);
        toast.success("Nouveau lot créé");
      }
    } catch (error) {
      
    }
  }
  const handleChange = ({currentTarget}) => setNewCheckBundle({...newCheckBundle, [currentTarget.name] : parseInt(currentTarget.value)});

  useEffect(()=> {
    setNewCheckBundle({...newCheckBundle, centerId: centerId})
  }, [])
  return (
    <>
      <div className=" mt-5 container mx-auto sm:px-6 lg:px-8">
        <form onSubmit={handleSubmit}>
          <div className="overflow-hidden shadow sm:rounded-md">
            <div className="bg-white px-4 py-5 sm:p-6">
              <div className="mb-3">
                <h1 className="text-xl">Ajouter un lot</h1>
              </div>
              <div className="grid grid-cols-6   gap-6">
                <div className="col-span-3">
                  <label
                    htmlFor="completeAdress"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Minimum
                  </label>
                  <input
                    type="number"
                    name="min"
                    value={newCheckBundle.min}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                  />
                </div>
                <div className="col-span-3">
                  <label
                    htmlFor="completeAdress"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Maximum
                  </label>
                  <input
                    type="number"
                    name="max"
                    value={newCheckBundle.max}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                  />
                </div>
                {/* <div className="col-span-2">
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id="comments"
                        aria-describedby="comments-description"
                        name="comments"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-maneo-500 focus:ring-maneo-500"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="comments"
                        className="font-medium text-gray-900"
                      >
                        Définir comme lot par défaut
                      </label>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
              <button
                type="submit"
                className="inline-flex justify-center rounded-md border border-transparent bg-green-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                Ajouter
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CenterCheckForm;
