export const countriesData = [
    {
      nom_de_la_commune: "ETANG SALE",
      code_postal: "97427",
      ligne_5: null,
    },
    {
      nom_de_la_commune: "ETANG SALE",
      code_postal: "97427",
      ligne_5: "ETANG SALE LES BAINS",
    },
    {
      nom_de_la_commune: "PETITE ILE",
      code_postal: "97429",
      ligne_5: "PITON GOYAVES",
    },
    {
      nom_de_la_commune: "LA PLAINE DES PALMISTES",
      code_postal: "97431",
      ligne_5: null,
    },
    {
      nom_de_la_commune: "SAINT ANDRE",
      code_postal: "97440",
      ligne_5: "LA CRESSONNIERE",
    },
    {
      nom_de_la_commune: "SAINT DENIS",
      code_postal: "97400",
      ligne_5: "SAINT FRANCOIS",
    },
    {
      nom_de_la_commune: "SAINT LEU",
      code_postal: "97424",
      ligne_5: "LE PITON SAINT LEU",
    },
    {
      nom_de_la_commune: "SAINT LEU",
      code_postal: "97424",
      ligne_5: "LE PLATE",
    },
    {
      nom_de_la_commune: "SAINT LOUIS",
      code_postal: "97421",
      ligne_5: "LA RIVIERE",
    },
    {
      nom_de_la_commune: "SAINT LOUIS",
      code_postal: "97421",
      ligne_5: "LES MAKES",
    },
    {
      nom_de_la_commune: "SAINT PAUL",
      code_postal: "97422",
      ligne_5: "LA SALINE",
    },
    {
      nom_de_la_commune: "SAINT PAUL",
      code_postal: "97435",
      ligne_5: "BERNICA",
    },
    {
      nom_de_la_commune: "SAINTE MARIE",
      code_postal: "97438",
      ligne_5: null,
    },
    {
      nom_de_la_commune: "SAINTE MARIE",
      code_postal: "97438",
      ligne_5: "ROLAND GARROS AEROPORT",
    },
    {
      nom_de_la_commune: "SALAZIE",
      code_postal: "97433",
      ligne_5: null,
    },
    {
      nom_de_la_commune: "LE TAMPON",
      code_postal: "97418",
      ligne_5: "LA PLAINE DES CAFRES",
    },
    {
      nom_de_la_commune: "SAINT BENOIT",
      code_postal: "97470",
      ligne_5: null,
    },
    {
      nom_de_la_commune: "SAINT BENOIT",
      code_postal: "97470",
      ligne_5: "BEAUFONDS",
    },
    {
      nom_de_la_commune: "SAINT DENIS",
      code_postal: "97400",
      ligne_5: "LE BRULE",
    },
    {
      nom_de_la_commune: "SAINT DENIS",
      code_postal: "97417",
      ligne_5: "SAINT BERNARD",
    },
    {
      nom_de_la_commune: "SAINT DENIS",
      code_postal: "97490",
      ligne_5: "LA BRETAGNE",
    },
    {
      nom_de_la_commune: "SAINT DENIS",
      code_postal: "97490",
      ligne_5: "MOUFIA",
    },
    {
      nom_de_la_commune: "SAINT DENIS",
      code_postal: "97490",
      ligne_5: "SAINTE CLOTILDE",
    },
    {
      nom_de_la_commune: "SAINT JOSEPH",
      code_postal: "97480",
      ligne_5: null,
    },
    {
      nom_de_la_commune: "SAINT LOUIS",
      code_postal: "97450",
      ligne_5: null,
    },
    {
      nom_de_la_commune: "SAINT PAUL",
      code_postal: "97434",
      ligne_5: "LA SALINE LES BAINS",
    },
    {
      nom_de_la_commune: "SAINT PAUL",
      code_postal: "97435",
      ligne_5: "TAN ROUGE",
    },
    {
      nom_de_la_commune: "SAINT PIERRE",
      code_postal: "97410",
      ligne_5: null,
    },
    {
      nom_de_la_commune: "SAINT PIERRE",
      code_postal: "97410",
      ligne_5: "BASSE TERRE",
    },
    {
      nom_de_la_commune: "SAINT PIERRE",
      code_postal: "97410",
      ligne_5: "GRAND BOIS",
    },
    {
      nom_de_la_commune: "SAINT PHILIPPE",
      code_postal: "97442",
      ligne_5: null,
    },
    {
      nom_de_la_commune: "SAINT PHILIPPE",
      code_postal: "97442",
      ligne_5: "BASSE VALLEE",
    },
    {
      nom_de_la_commune: "SAINTE MARIE",
      code_postal: "97438",
      ligne_5: "LA GRANDE MONTEE",
    },
    {
      nom_de_la_commune: "LES TROIS BASSINS",
      code_postal: "97426",
      ligne_5: null,
    },
    {
      nom_de_la_commune: "CILAOS",
      code_postal: "97413",
      ligne_5: "PALMISAINTE ROUGE",
    },
    {
      nom_de_la_commune: "ENTRE DEUX",
      code_postal: "97414",
      ligne_5: null,
    },
    {
      nom_de_la_commune: "PETITE ILE",
      code_postal: "97429",
      ligne_5: null,
    },
    {
      nom_de_la_commune: "LE PORT",
      code_postal: "97420",
      ligne_5: "LE PORT MARINE",
    },
    {
      nom_de_la_commune: "LE PORT",
      code_postal: "97420",
      ligne_5: "LE PORT ZUP",
    },
    {
      nom_de_la_commune: "SAINT ANDRE",
      code_postal: "97440",
      ligne_5: null,
    },
    {
      nom_de_la_commune: "SAINT BENOIT",
      code_postal: "97437",
      ligne_5: "SAINTE ANNE",
    },
    {
      nom_de_la_commune: "SAINT DENIS",
      code_postal: "97400",
      ligne_5: "BELLE PIERRE",
    },
    {
      nom_de_la_commune: "SAINT DENIS",
      code_postal: "97417",
      ligne_5: "LA MONTAGNE",
    },
    {
      nom_de_la_commune: "SAINT PAUL",
      code_postal: "97434",
      ligne_5: "SAINT GILLES LES BAINS",
    },
    {
      nom_de_la_commune: "SAINT PAUL",
      code_postal: "97435",
      ligne_5: "SAINT GILLES LES HAUTS",
    },
    {
      nom_de_la_commune: "SAINT PIERRE",
      code_postal: "97410",
      ligne_5: "TERRE SAINTE",
    },
    {
      nom_de_la_commune: "SAINTE SUZANNE",
      code_postal: "97441",
      ligne_5: null,
    },
    {
      nom_de_la_commune: "LE TAMPON",
      code_postal: "97430",
      ligne_5: null,
    },
    {
      nom_de_la_commune: "LE TAMPON",
      code_postal: "97430",
      ligne_5: "LES TROIS MARES",
    },
    {
      nom_de_la_commune: "LE TAMPON",
      code_postal: "97430",
      ligne_5: "TAMPON 14EME KM",
    },
    {
      nom_de_la_commune: "CILAOS",
      code_postal: "97413",
      ligne_5: null,
    },
    {
      nom_de_la_commune: "LES AVIRONS",
      code_postal: "97425",
      ligne_5: null,
    },
    {
      nom_de_la_commune: "BRAS PANON",
      code_postal: "97412",
      ligne_5: null,
    },
    {
      nom_de_la_commune: "BRAS PANON",
      code_postal: "97412",
      ligne_5: "RIVIERE DU MAT",
    },
    {
      nom_de_la_commune: "LA POSSESSION",
      code_postal: "97419",
      ligne_5: null,
    },
    {
      nom_de_la_commune: "LA POSSESSION",
      code_postal: "97419",
      ligne_5: "LE DOS D ANE",
    },
    {
      nom_de_la_commune: "LA POSSESSION",
      code_postal: "97419",
      ligne_5: "SAINTE THERESE",
    },
    {
      nom_de_la_commune: "SAINT DENIS",
      code_postal: "97400",
      ligne_5: null,
    },
    {
      nom_de_la_commune: "SAINT DENIS",
      code_postal: "97400",
      ligne_5: "SAINT DENIS CAMELIAS",
    },
    {
      nom_de_la_commune: "SAINT DENIS",
      code_postal: "97490",
      ligne_5: "BOIS DE NEFLES SAINT DENIS",
    },
    {
      nom_de_la_commune: "SAINT DENIS",
      code_postal: "97490",
      ligne_5: "SAINT DENIS CHAUDRON",
    },
    {
      nom_de_la_commune: "SAINT JOSEPH",
      code_postal: "97480",
      ligne_5: "LES LIANES",
    },
    {
      nom_de_la_commune: "SAINT JOSEPH",
      code_postal: "97480",
      ligne_5: "VINCENDO",
    },
    {
      nom_de_la_commune: "SAINT LEU",
      code_postal: "97416",
      ligne_5: "LA CHALOUPE",
    },
    {
      nom_de_la_commune: "SAINT PAUL",
      code_postal: "97460",
      ligne_5: null,
    },
    {
      nom_de_la_commune: "SAINTE MARIE",
      code_postal: "97438",
      ligne_5: "RIVIERE DES PLUIES",
    },
    {
      nom_de_la_commune: "SAINTE ROSE",
      code_postal: "97439",
      ligne_5: null,
    },
    {
      nom_de_la_commune: "SAINTE ROSE",
      code_postal: "97439",
      ligne_5: "LE PITON SAINTE ROSE",
    },
    {
      nom_de_la_commune: "SALAZIE",
      code_postal: "97433",
      ligne_5: "HELL BOURG",
    },
    {
      nom_de_la_commune: "LE TAMPON",
      code_postal: "97430",
      ligne_5: "PONT D YVES",
    },
    {
      nom_de_la_commune: "LES AVIRONS",
      code_postal: "97425",
      ligne_5: "TEVELAVE",
    },
    {
      nom_de_la_commune: "LE PORT",
      code_postal: "97420",
      ligne_5: null,
    },
    {
      nom_de_la_commune: "LA POSSESSION",
      code_postal: "97419",
      ligne_5: "LA RIVIERE DES GALETS",
    },
    {
      nom_de_la_commune: "SAINT ANDRE",
      code_postal: "97440",
      ligne_5: "CAMBUSTON",
    },
    {
      nom_de_la_commune: "SAINT DENIS",
      code_postal: "97400",
      ligne_5: "SAINT DENIS TADAR",
    },
    {
      nom_de_la_commune: "SAINT JOSEPH",
      code_postal: "97480",
      ligne_5: "JEAN PETIT",
    },
    {
      nom_de_la_commune: "SAINT LEU",
      code_postal: "97436",
      ligne_5: null,
    },
    {
      nom_de_la_commune: "SAINT PAUL",
      code_postal: "97411",
      ligne_5: "BOIS DE NEFLES SAINT PAUL",
    },
    {
      nom_de_la_commune: "SAINT PAUL",
      code_postal: "97423",
      ligne_5: "LE GUILLAUME",
    },
    {
      nom_de_la_commune: "SAINT PAUL",
      code_postal: "97460",
      ligne_5: "BELLEMENE",
    },
    {
      nom_de_la_commune: "SAINT PAUL",
      code_postal: "97460",
      ligne_5: "PLATEAU CAILLOUX",
    },
    {
      nom_de_la_commune: "SAINT PIERRE",
      code_postal: "97410",
      ligne_5: "MONT VERT",
    },
    {
      nom_de_la_commune: "SAINT PIERRE",
      code_postal: "97410",
      ligne_5: "RAVINE BLANCHE",
    },
    {
      nom_de_la_commune: "SAINT PIERRE",
      code_postal: "97432",
      ligne_5: "RAVINE DES CABRIS",
    },
    {
      nom_de_la_commune: "LE TAMPON",
      code_postal: "97418",
      ligne_5: "TAMPON 17EME KM",
    },
  ];