import React from "react";
import axios from "axios";
import { SERVER_URL, headers } from "../../app/config";
import { SIGN_IN, VALID_TOKEN } from "../graphql/auth";
import { callApi } from ".";

const signIn = async (credentials) => {
  return await callApi(SERVER_URL, SIGN_IN, credentials, {
    ...headers,
    authorization: localStorage.getItem("token"),
  });
};

const isValidToken = async (token) => {
  return await callApi(
    SERVER_URL,
    VALID_TOKEN,
    { token },
    { ...headers, authorization: localStorage.getItem("token") }
  );
};

export const authApi = { signIn, isValidToken };
