import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../services/api";

export const createOrUpdateCoupon = createAsyncThunk(
  "sponsorCoupon/createOrUpdateCoupon",
  async (data, thunkApi) => {
    const result = await api.couponApi.createOrUpdateCoupon(data);
    if (result.errors || !result.data) {
      return thunkApi.abort(result.errors[0].message);
    } else {
      return result;
    }
  }
);
