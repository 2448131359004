import { PENDING_STATUS, RECEIVED_STATUS, REJECTED_STATUS } from "services/graphql/sponsorcoupon";
import { LOCK_SPONSOR_LIST } from "services/graphql/sponsorlist";

export class FileStatusEnum {
    constructor() {
        this.received = "RECEIVED";
        this.pending = "PENDING";
        this.rejected = "REJECTED";
    }

    getGraphlRoute(status) {
        if(status === this.received) return RECEIVED_STATUS; 
        if(status === this.pending) return PENDING_STATUS; 
        if(status === this.rejected) return REJECTED_STATUS; 
    }

}