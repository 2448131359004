import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../services/api";

export const getSponsorListByCenter = createAsyncThunk(
  "sponsorList/getSponsorListByCenter",
  async (data, thunkApi) => {
    const result = await api.sponsorListApi.getSponsorListByCenter(data);
    if (result.errors || !result.data) {
      return thunkApi.abort(result.errors[0].message);
    } else {
      return result;
    }
  }
);

export const createSponsorList = createAsyncThunk(
  "sponsorList/createSponsorList",
  async (data, thunkApi) => {
    const result = await api.sponsorListApi.createSponsorList(data);
    if (result.errors || !result.data) {
      return thunkApi.abort(result.errors[0].message);
    } else {
      return result;
    }
  }
);

export const getSponsorListById = createAsyncThunk(
  "sponsorList/getSponsorListById",
  async (id, thunkApi) => {
    const result = await api.sponsorListApi.getSponsorList(id);
    if (result.errors || !result.data) {
      return thunkApi.abort(result.errors[0].message);
    } else {
      return result;
    }
  }
);
