import React, { useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/fr";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import {
  updateBoth,
  updateEndDate,
  updateStartDate,
} from "../../features/period/periodSlice";
import ExportCsv from "pages/export/exportCsv";

const CenterDateRangePicker = () => {
  const dispatch = useDispatch();
  const { endDate, startDate, listYear } = useSelector((state) => state.period);
  const [dates, setDates] = useState({
    startDate: startDate,
    endDate: endDate,
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateBoth(dates));
  };

  return (
    <>
      <form  onSubmit={handleSubmit}>
        <div className="flex mt-5">
          <div className="mr-3">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                value={moment(dates.startDate, "YYYY-MM-DD")}
                label="Date de début"
                minDate={moment("2016-01-01", "YYYY-MM-DD")}
                maxDate={moment(dates.endDate, "YYYY-MM-DD")}
                slotProps={{ textField: { size: "small" } }}
                onChange={(event) => {
                  if (event._isValid) {
                    setDates({
                      ...dates,
                      startDate: moment(event._d).format("YYYY-MM-DD"),
                    });
                  }
                }}
              />
            </LocalizationProvider>
          </div>

          <div className="mr-3">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                value={moment(dates.endDate, "YYYY-MM-DD")}
                label="Date de fin"
                minDate={moment(dates.startDate, "YYYY-MM-DD")}
                maxDate={moment()}
                slotProps={{
                  textField: { size: "small" },
                }}
                onChange={(event) =>
                  setDates({
                    ...dates,
                    endDate: moment(event._d).format("YYYY-MM-DD"),
                  })
                }
              />
            </LocalizationProvider>
          </div>
          <div className="">
            <button
              type="submit"
              className="inline-flex items-center gap-x-1.5 rounded-md bg-maneo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-maneo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-maneo-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
              Rechercher
            </button>
          </div>
          <div className="ml-3">
            <ExportCsv />
          </div>
        </div>
      </form>
    </>
  );
};

export default CenterDateRangePicker;
