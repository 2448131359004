export const GET_CENTERS = `#graphql
query GetAllCenter {
  getAllCenter {
    name
    id
    currentCheckBundle {
      id
      current
      min
      max
    }
  }
}`;

export const GET_CENTER_BY_ID = `#graphql
query GetCenter($centerId: ID) {
  getCenter(centerId: $centerId) {
    id
    name
    zipCode
    address
    checksBundle {
      id
      min
      max
      current
      centerId
    }
    currentCheckBundle {
      id
      current
      min
      max
    }
  }
}`;

export const UPDATE_CENTER = `#graphql
  mutation UpdateCenter($center: CenterInput) {
  updateCenter(center: $center) {
    id
    address
    name
    zipCode
    currentCheckBundle {
      id
      centerId
      current
      max
      min
    }
    checksBundle {
      id
      centerId
      current
      min
      max
    }
  }
}
`;

export const ADD_CHECK_BUNDLE = `#graphql
  mutation CreateCheckBundle($max: Int, $min: Int!, $centerId: ID!) {
  createCheckBundle(max: $max, min: $min, centerId: $centerId) {
    centerId
    id
    current
    max
    min
  }
}
`

export const CURRENT_CHECK_NUMBER = `#graphql
query Query($centerId: ID!) {
  getCurrentCheckNumber(centerId: $centerId)
}
`;