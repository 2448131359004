import React, { useState, Fragment } from 'react';
import { Dialog, Transition } from "@headlessui/react";
import {
  TrashIcon,
} from "@heroicons/react/24/outline";
import { graphql } from 'services/api';
import { DELETE_SPONSOR_COUPON } from 'services/graphql/sponsorcoupon';
import { toast } from 'react-toastify';

const ModalDeleteCoupon = ({sponsorCouponId, setReload}) => {
    const [open, setOpen] = useState(false);

    const deleteCoupon = async () => {
        try {
            const result = await graphql({sponsorCouponId: sponsorCouponId}, DELETE_SPONSOR_COUPON);
            if (result.errors || !result.data) {
                toast.error(
                  "Une erreur est survenu lors de la suppression du coupon."
                );
              } else {
                toast.success('Suppression réussi ! pensez a repositionner le numéro de chèque si nécessaire.');
                setOpen(false);
                setReload(true);
              }
        } catch (error) {
            
        }
    }

    const handleDelete = () => {
        deleteCoupon();
    }
    return ( <>
        <>
      <div className="flex items-center">
        <button
          type="button"
          onClick={() => setOpen(!open)}
          className="mr-2 inline-flex items-center gap-x-1.5 rounded-full bg-red-200  border-red-500 border-1 px-3 py-3 text-sm  shadow-sm hover:bg-red-300"
        >
          <TrashIcon className="h-5 w-5 text-red-500" />{" "}
        </button>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <TrashIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Supprimer parrainage
                      </Dialog.Title>
                      <div className="mt-2">
                      <p className="text-sm text-gray-500">
                          Souhaitez-vous réellement supprimer ce parrainage ?
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      onClick={handleDelete}
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    >
                      Supprimer
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                    >
                      Annuler
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
    </> );
}
 
export default ModalDeleteCoupon;