import React from "react";
import CenterSelector from "components/center/CenterSelector";
import CenterDateRangePicker from "components/center/CenterDateRangePicker";
import CustomerList from "components/statistic/CustomerList";

const Home = () => {
  return (
    <>
      <div className=" mt-5 container mx-auto sm:px-6 lg:px-8">
        <div className="pt-5">
          <CenterSelector />
        </div>
        <div className="mt-3">
          <CustomerList />
        </div>
      </div>
    </>
  );
};

export default Home;
