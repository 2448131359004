import { useRef, useState } from "react";

import Select from "react-select";
import { formatSelectCountries } from "../functions/utils";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSponsorListById } from "../../features/sponsor/list/sponsorListAction";
import { api } from "../../services/api";
import CustomSelect from "../inputs/CustomSelect";
import {
  initCoupon,
  updateCoupon,
} from "../../features/sponsor/coupon/sponsorCouponSlice";
import { toast } from "react-toastify";

const genders = [
  { label: "M", value: "M" },
  { label: "Mme", value: "MME" },
];
const prices = [
  { label: "10 €", value: "10" },
  { label: "15 €", value: "15" },
  { label: "50 €", value: "50" },
];
const countries = formatSelectCountries();

const SponsorCouponForm = () => {
  const params = useParams();
  const { coupon } = useSelector((state) => state.sponsorCoupon);
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const [type, name] = e.currentTarget.name.split("-");
    dispatch(
      updateCoupon({
        ...coupon,
        [type]: { ...coupon[type], [name]: e.currentTarget.value },
      })
    );
  };
  const formatData = (data) => {
    let c = JSON.parse(JSON.stringify(coupon));
    c.sponsorListId = params.id;
    if (c.id) {
      c.couponId = c.id;
    }
    return c;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const c = formatData();
    const result = await api.couponApi.createOrUpdateCoupon(c);
    if (result.errors || !result.data) {
      toast.error(
        "Une erreur est survenu lors de la création, veuillez réessayer !"
      );
      console.log(result.errors);
    } else {
      toast.success("Ajout/modification réussie");
    }
    dispatch(getSponsorListById(params.id));
    dispatch(initCoupon());
  };
  
  return (
    <>
      <form
        className=" bg-white shadow-md sm:rounded-xl ring-1 ring-gray-900/5 mb-5"
        onSubmit={handleSubmit}
      >
        <div className="px-5 py-2">
          <h2 className="text-lg text-gray-900 font-bold border-b border-gray-200">
            Formulaire parrainage
          </h2>
          <div className="mt-4 flex">
            <CustomSelect
              required
              value={coupon.amount}
              placeholder={"Selectionnez un montant"}
              onChange={(e) => {
                dispatch(
                  updateCoupon({ ...coupon, amount: parseInt(e.value) })
                );
              }}
              options={prices}
            />
          </div>
        </div>
        <div className="w-full flex flex-col h-full">
          <div className="w-full shrink lg:block px-5">
            <h2 className="text-lg text-gray-900 font-bold border-b border-gray-200">
              Parrain
            </h2>

            <div className="mt-4 grid grid-cols-1 gap-y-1 sm:grid-cols-12 sm:gap-x-4">
              <div className="col-span-2 sm:col-span-12">
                <div>
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Civilité
                  </label>
                  <div className="mt-1">
                    <CustomSelect
                      required
                      value={coupon.sponsor.gender}
                      placeholder={"Choisir"}
                      onChange={(e) => {
                        dispatch(
                          updateCoupon({
                            ...coupon,
                            sponsor: {
                              ...coupon.sponsor,
                              gender: e.value,
                            },
                          })
                        );
                      }}
                      options={genders}
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-5 sm:col-span-6">
                <label
                  htmlFor="sponsor-firstName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Prénom
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    onChange={handleChange}
                    value={coupon.sponsor.firstName}
                    id="sponsor-firstName"
                    name="sponsor-firstName"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                    required
                  />
                </div>
              </div>

              <div className="col-span-5 sm:col-span-6">
                <label
                  htmlFor="sponsor-lastName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nom
                </label>
                <div className="mt-1">
                  <input
                    required
                    type="text"
                    onChange={handleChange}
                    value={coupon.sponsor.lastName}
                    id="sponsor-lastName"
                    name="sponsor-lastName"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="sm:col-span-6">
                <label
                  htmlFor="sponsor-address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Adresse
                </label>
                <div className="mt-1">
                  <input
                    required
                    value={coupon.sponsor.address}
                    type="text"
                    onChange={handleChange}
                    name="sponsor-address"
                    id="sponsor-address"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="sm:col-span-6">
                <label
                  htmlFor="sponsor-completeAddress"
                  className="block text-sm font-medium text-gray-700"
                >
                  Adr. complète
                </label>
                <div className="mt-1">
                  <input
                    value={coupon.sponsor.completeAddress}
                    type="text"
                    onChange={handleChange}
                    name="sponsor-completeAddress"
                    id="sponsor-completeAddress"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="sm:col-span-12">
                <label
                  htmlFor="sponsor-zipCode"
                  className="block text-sm font-medium text-gray-700"
                >
                  Code postal
                </label>
                <div className="mt-1">
                  <Select
                    required
                    type="text"
                    name="sponsor-zipCode"
                    id="sponsor-zipCode"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                    value={{
                      value: countries.findIndex(
                        (c) => c === coupon.sponsor.zipCode
                      ),
                      label: coupon.sponsor.zipCode,
                    }}
                    onChange={(e) =>
                      dispatch(
                        updateCoupon({
                          ...coupon,
                          sponsor: {
                            ...coupon.sponsor,
                            zipCode: e.label,
                          },
                        })
                      )
                    }
                    options={countries.map((c, i) => {
                      return { value: i, label: c };
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full shrink lg:block p-5">
            <h2 className="text-lg text-gray-900 font-bold border-b border-gray-200">
              Filleul
            </h2>

            <div className="mt-4 grid grid-cols-1 gap-y-1 sm:grid-cols-12 sm:gap-x-4">
              <div className="col-span-2 sm:col-span-12">
                <div>
                  <label
                    htmlFor="customer-gender"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Civilité
                  </label>
                  <div className="mt-1">
                    <CustomSelect
                      required
                      value={coupon.customer.gender}
                      placeholder={"Choisir"}
                      onChange={(e) => {
                        dispatch(
                          updateCoupon({
                            ...coupon,
                            customer: {
                              ...coupon.customer,
                              gender: e.value,
                            },
                          })
                        );
                      }}
                      options={genders}
                    />
                  </div>
                </div>
              </div>
              <div className="sm:col-span-6">
                <label
                  htmlFor="customer-firstName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Prénom
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    required
                    value={coupon.customer.firstName}
                    onChange={handleChange}
                    id="customer-firstName"
                    name="customer-firstName"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="sm:col-span-6">
                <label
                  htmlFor="customer-lastName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nom
                </label>
                <div className="mt-1">
                  <input
                    required
                    value={coupon.customer.lastName}
                    type="text"
                    onChange={handleChange}
                    id="customer-lastName"
                    name="customer-lastName"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="sm:col-span-6">
                <label
                  htmlFor="customer-address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Adresse
                </label>
                <div className="mt-1">
                  <input
                    required
                    value={coupon.customer.address}
                    type="text"
                    onChange={handleChange}
                    name="customer-address"
                    id="customer-address"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="sm:col-span-6">
                <label
                  htmlFor="customer-completeAddress"
                  className="block text-sm font-medium text-gray-700"
                >
                  Adr. complète
                </label>
                <div className="mt-1">
                  <input
                    value={coupon.customer.completeAddress}
                    type="text"
                    onChange={handleChange}
                    name="customer-completeAddress"
                    id="customer-completeAddress"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="sm:col-span-12">
                <label
                  htmlFor="customer-zipCode"
                  className="block text-sm font-medium text-gray-700"
                >
                  Code postal
                </label>
                <div className="mt-1">
                  <Select
                    required
                    type="text"
                    name="customer-zipCode"
                    id="customer-zipCode"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                    value={{
                      value: countries.findIndex(
                        (c) => c === coupon.customer.zipCode
                      ),
                      label: coupon.customer.zipCode,
                    }}
                    onChange={(e) =>
                      dispatch(
                        updateCoupon({
                          ...coupon,
                          customer: {
                            ...coupon.customer,
                            zipCode: e.label,
                          },
                        })
                      )
                    }
                    options={countries.map((c, i) => {
                      return { value: i, label: c };
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-shrink-0 justify-between px-4 py-4">
          <button
            type="button"
            className="ml-4 inline-flex justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            onClick={() => dispatch(initCoupon())}
          >
            Effacer
          </button>
          <button
            type="submit"
            className={`ml-4 inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${coupon.id ? "bg-amber-500 hover:bg-amber-700 focus-visible:outline-amber-600" : "bg-green-500 hover:bg-green-700 focus-visible:outline-green-600"}`}
              
          >
            {coupon.id ? "Mettre à jour" : "Enregistrer"}
            
          </button>
        </div>
      </form>
    </>
  );
};

export default SponsorCouponForm;
