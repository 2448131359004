export const GET_STATS_BY_CENTER = `#graphql
    query GetCouponsStatsByCenter(
        $centerId: ID!
        $startDate: Date
        $endDate: Date
    ) {
        getCouponsStatsByCenter(
            centerId: $centerId
            startDate: $startDate
            endDate: $endDate
        ) {
            totalcoupon
            idcustomer
            gender
            lastname
            firstname
            address
            completeaddress
            zipcode
        }
}
 `;