import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SponsorCouponStatusEnum } from "../../../features/sponsor/coupon/sponsorEnum";
import Loader from "../../utils/Loader";
import moment from "moment";
import { PencilIcon, PencilSquareIcon, PlusIcon } from "@heroicons/react/24/outline";
import SponsorCouponForm from "../../forms/SponsorCouponForm";
import { getSponsorListById } from "../../../features/sponsor/list/sponsorListAction";
import { useParams } from "react-router-dom";
import { updateCoupon } from "../../../features/sponsor/coupon/sponsorCouponSlice";
import { Menu, Transition } from "@headlessui/react";
import { FileStatusEnum } from "enum/FileStatusEnum";
import { graphql } from "services/api";
import { toast } from "react-toastify";
import ModalUpdateCurrentCheck from "components/checkbundle/ModalUpdateCurrentCheck";
import ModalDeleteCoupon from "./ModalDeleteCoupon";
moment.locale("fr");

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SponsorStackedCoupon = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const statusEnum = new FileStatusEnum();
  const [reload, setReload] = useState(false);

  const { currentSponsorList, loading } = useSelector(
    (state) => state.sponsorList
  );

  const changeStatus = async (id, status) => {
    try {
      const result = await graphql(
        { sponsorCouponId: id },
        statusEnum.getGraphlRoute(status)
      );
      if (result.errors || !result.data) {
        toast.error(
          "Une erreur est survenu dans le changement de statut du coupon."
        );
      } else {
        setReload(true);
      }
    } catch (error) {}
  };

  const handleChangeStatus = (id, status) => {
    changeStatus(id, status);
  };
  useEffect(() => {
    dispatch(getSponsorListById(params.id));
  }, []);

  useEffect(() => {
    if (reload) {
      dispatch(getSponsorListById(params.id));
      setReload(false);
    }
  }, [reload]);
  return loading ? (
    <>
      <Loader />
    </>
  ) : currentSponsorList && currentSponsorList.coupons.length > 0 ? (
    <>
      <div className=" my-5 ">
        <div className=" flow-root">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      N°
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Parrain
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Filleul
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Etat
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Montant
                    </th>
                    {!currentSponsorList.isLocked && (
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                      >
                        <span className="sr-only">Modifier</span>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className="h-full divide-gray-200 bg-white">
                  {currentSponsorList.coupons.map((coupon, index) => (
                    <tr key={index}>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        {coupon.checkNumber}
                      </td>
                      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                        <div className="flex items-center">
                          <div className="ml-4">
                            <div className="font-medium text-gray-900 normal-case">
                              <p className="normal-case">
                                {coupon.sponsor.lastName +
                                  " " +
                                  coupon.sponsor.firstName}
                              </p>
                            </div>
                            <div className="mt-1 text-gray-500 lowercase">
                              {coupon.sponsor.address +
                                " " +
                                coupon.sponsor.completeAddress +
                                " " +
                                coupon.sponsor.zipCode}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        {coupon.customer && (
                          <>
                            <div className="text-gray-900">
                              {coupon.customer.lastName +
                                " " +
                                coupon.customer.firstName}
                            </div>
                            <div className="mt-1 text-gray-500 lowercase">
                              {coupon.customer.address +
                                " " +
                                coupon.customer.completeAddress +
                                " " +
                                coupon.customer.zipCode}
                            </div>
                          </>
                        )}
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 flex items-center">
                        {coupon.status && (
                          <>
                            <span
                              className={`inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium  ring-1 ring-inset ${
                                coupon.status === "PENDING" &&
                                "text-amber-700 ring-amber-600/20 bg-amber-50"
                              } ${
                                coupon.status === "REJECTED" &&
                                "text-red-700  ring-red-600/20 bg-red-50"
                              } ${
                                coupon.status === "RECEIVED" &&
                                "text-green-700  ring-green-600/20 bg-green-50"
                              }`}
                            >
                              {SponsorCouponStatusEnum.getLabel(coupon.status)}
                            </span>
                          </>
                        )}
                        <Menu as="div" className="relative flex-none ml-2">
                          <Menu.Button className="-m-2.5 p-2.5 text-gray-500 hover:text-gray-900">
                            <span className="sr-only">Open options</span>
                            <PencilIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    onClick={() =>
                                      handleChangeStatus(
                                        coupon.id,
                                        statusEnum.received
                                      )
                                    }
                                    className={classNames(
                                      active ? "bg-gray-50" : "",
                                      "block px-3 py-1 text-sm leading-6 text-green-600 w-full text-left"
                                    )}
                                  >
                                    Reçu
                                  </button>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    onClick={() =>
                                      handleChangeStatus(
                                        coupon.id,
                                        statusEnum.rejected
                                      )
                                    }
                                    className={classNames(
                                      active ? "bg-gray-50" : "",
                                      "block px-3 py-1 text-sm leading-6 text-red-600 w-full text-left"
                                    )}
                                  >
                                    Rejeté
                                  </button>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    onClick={() =>
                                      handleChangeStatus(
                                        coupon.id,
                                        statusEnum.pending
                                      )
                                    }
                                    className={classNames(
                                      active ? "bg-gray-50" : "",
                                      "block px-3 py-1 text-sm leading-6 text-amber-600 w-full text-left"
                                    )}
                                  >
                                    En attente
                                  </button>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 text-right">
                        {coupon.amount + " €"}
                      </td>
                      {!currentSponsorList.isLocked && (
                        <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0 flex justify-end">
                          <button
                            href="#"
                            className="mr-2 inline-flex items-center gap-x-1.5 rounded-full bg-amber-200  border-amber-500 border-1 px-3 py-3 text-sm  shadow-sm hover:bg-amber-300"
                            onClick={() => dispatch(updateCoupon(coupon))}
                          >
                            <PencilSquareIcon className="h-5 w-5 text-amber-500"/>
                          </button>

                          <ModalDeleteCoupon sponsorCouponId={coupon.id} setReload={setReload}/> 
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="h-1/2 flex items-center justify-center text-gray-800 itali">
        <span>
          <em>Aucunes données trouvées</em>
        </span>
      </div>
    </>
  );
};

export default SponsorStackedCoupon;
