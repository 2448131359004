import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { api } from "../../services/api";
import { updateCoupon } from "../../features/sponsor/coupon/sponsorCouponSlice";

const CustomerSuggestion = (props) => {
  const [customers, setCustomers] = useState([]);
  const { coupon } = useSelector((state) => state.sponsorCoupon);
  const dispatch = useDispatch();

  const fetchCustomers = async () => {
    try {
      const result = await api.customerApi.getCustomers();
      if (result.errors || !result.data) {
        return console.log(result.errors);
      } else {
        setCustomers(result.data.findAllCustomer);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (coupon.sponsor.firstName.length > 2) {
      fetchCustomers();
    }
  }, [coupon.sponsor.firstName, coupon.sponsor.lastName]);

  return (
    <>
      <div className={`bg-white grid grid-cols-4 `}>
        {customers &&
          customers.length > 0 &&
          (coupon.sponsor.firstName.length > 2 ||
          coupon.sponsor.lastName.length > 2 ) &&
          customers
            .filter((c) => {
              return (
                c.firstName
                  .toUpperCase()
                  .includes(coupon.sponsor.firstName.toUpperCase()) &&
                c.lastName
                  .toUpperCase()
                  .includes(coupon.sponsor.lastName.toUpperCase())
              );
            })
            .slice(0, 4)
            .map((c, index) => {
              return (
                <div
                  key={index}
                  className={`border-dashed  border-2 border-maneo-500 rounded-md mx-2 flex flex-col text-center hover:bg-maneo-500 hover:text-white cursor-pointer`}
                  onClick={() => {
                    dispatch(updateCoupon({ ...coupon, sponsor: c }));
                  }}
                >
                  <span className="capitalize">
                    {c.lastName + " " + c.firstName}
                  </span>
                  <span className="lowercase">
                    {c.address + " " + c.completeAddress}
                  </span>
                  <span>{c.zipCode}</span>
                </div>
              );
            })}
      </div>
    </>
  );
};

export default CustomerSuggestion;
