import moment from "moment";
import { countriesData } from "./countries";
moment.locale("fr");

export const listYear = (startDate, endDate) => {
  const first = parseInt(startDate.year());
  const second = parseInt(endDate.year());
  let arr = Array();
  for (let i = first; i <= second; i++) arr.push(i);
  return arr;
};

export const formatSelectCountries = () => {
  const list = [];
  const data = countriesData.sort((a, b) => {
    return a.code_postal - b.code_postal;
  });

  return data.map((country) => {
    const result =
      country.code_postal +
      " " +
      (country.ligne_5 ? country.ligne_5 : country.nom_de_la_commune);
    return result;
  });

  //return list;
};
