export const GET_ALL_SPONSOR_LIST_BY_CENTER_AND_DATE = `#graphql 
  query GetSponsorListsbyCenter($centerId: ID!, $startDate: Date!, $endDate: Date!) {
    getSponsorListsbyCenter(id: $centerId, startDate: $startDate, endDate: $endDate) {
        name
        isLocked
        id
        editionAt
        createdAt
    }
}`;

export const GET_SPONSOR_LIST = `#graphql 
  query GetSponsorList($sponsorListId: ID!) {
    getSponsorList(id: $sponsorListId) {
      id
      name
      isLocked
      editionAt
      createdAt
      center {
        id
      }
      coupons {
        id
        checkNumber
        amount
        status
        sponsor {
          zipCode
          lastName
          id
          gender
          firstName
          completeAddress
          address
        }
        customer {
          zipCode
          lastName
          id
          gender
          firstName
          address
          completeAddress
        } 
      }
    }  
}`;

export const GET_SPONSOR_LIST_ASC = `#graphql 
  query GetSponsorListAsc($sponsorListId: ID!) {
    getSponsorListAsc(id: $sponsorListId) {
      id
      name
      isLocked
      editionAt
      createdAt
      center {
        id
      }
      coupons {
        id
        checkNumber
        amount
        status
        sponsor {
          zipCode
          lastName
          id
          gender
          firstName
          completeAddress
          address
        }
        customer {
          zipCode
          lastName
          id
          gender
          firstName
          address
          completeAddress
        } 
      }
    }  
}`;


export const CREATE_SPONSOR_LIST = `#graphql
  mutation CreateSponsorList($centerId: ID!, $editionAt: Date) {
    createSponsorList(centerId: $centerId, editionAt: $editionAt) {
      id
    }
} `;

export const LOCK_SPONSOR_LIST = `#graphql
  mutation LockSponsorList($sponsorListId: ID!) {
  lockSponsorList(sponsorListId: $sponsorListId) {
    id
    isLocked
  }
}
`;
