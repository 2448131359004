import React, { useEffect, useState } from "react";
import Select from "react-select";

const CustomSelect = (props) => {
  const [val, setVal] = useState(null);
  const setValue = () => {
    const result =
      props.options.find((option) => {
        const value = props.value ?? "";
        return option.value.toString() === value.toString();
      }) ?? null;
    setVal(result);
  };

  useEffect(() => {
    setValue();
  }, [props.value]);
  return (
    <Select
      required={props.required ?? false}
      placeholder={props.placeholder ?? ""}
      value={val}
      onChange={props.onChange}
      options={props.options}
    />
  );
};

export default CustomSelect;
