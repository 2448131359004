import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../services/api";

export const signIn = createAsyncThunk(
  "auth/signIn",
  async ({ login, password }, thunkApi) => {
    const result = await api.authApi.signIn({ login, password });
    if (result.errors) {
      return thunkApi.abort(result.errors[0].message);
    } else {
      return result;
    }
  }
);

export const isValidToken = createAsyncThunk(
  "auth/isValidToken",
  async (token, thunkApi) => {
    const result = await api.authApi.isValidToken(token);
    if (result.errors) {
      return thunkApi.abort(result.errors[0].message);
    } else {
      return result;
    }
  }
);
