import { UPDATE_CURRENT_CHECKBUNDLE } from "services/graphql/checkbundle";
import { callApi } from ".";
import { APP_API, SERVER_URL } from "../../app/config";

const headers = {
  "Content-Type": "application/json",
  app: APP_API,
};

const updateCurrentCheck = async (data) => {
  return await callApi(SERVER_URL, UPDATE_CURRENT_CHECKBUNDLE, data, {
    ...headers,
    authorization: localStorage.getItem("token"),
  });
};


export const checkApi = { updateCurrentCheck }
