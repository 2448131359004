export const CUSTOMERS = `#graphql
    query FindAllCustomer {
        findAllCustomer {
            id
            gender
            firstName
            lastName
            address
            completeAddress
            zipCode
        }
    }
`;

export const CUSTOMER_DETAILS = `#graphql
    query CustomerDetails($customerId: ID!, $startDate: Date, $endDate: Date) {
        customerDetails(customerId: $customerId, startDate: $startDate, endDate: $endDate) {
            id
            lastName
            firstName
            address
            completeAddress
            zipCode
            sponsored {
            id
            status
            editionAt
            checkNumber
            amount
            customer {
                lastName
                firstName
                address
                completeAddress
                zipCode
            }
            
            }
        }
    }
`;
