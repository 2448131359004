import React, { useEffect, useState } from "react";
import Signature from "assets/images/signature.png";
import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  View,
  PDFViewer,
} from "@react-pdf/renderer";
import moment from "moment";
import { useSelector } from "react-redux";
import { GET_SETTING } from "services/graphql/setting";
import { toast } from "react-toastify";
import Loader from "components/utils/Loader";
import { graphql } from "services/api";

moment.locale("fr");
const styleSheet = StyleSheet.create({
  coreMessage: {
    position: "absolute",
    fontSize: "10px",
    left: "2.1cm",
    width: "17.61cm",
    height: "11.18cm",
    top: "5.2cm",
  },
  check: {
    fontSize: "10px",
    left: "2.1cm",
    position: "absolute",
    top: "23.485cm",
    height: "5.5cm",
    width: "18cm",
  },
  logo: {
    position: "absolute",
    top: "-1.1cm",
    left: "-0.5cm",
    width: "8cm",
  },
});

const numberToText = [
  { number: 10, text: "xxxxx dix euros xxxxx" },
  { number: 15, text: "xxxxx quinze euros xxxxx" },
  { number: 50, text: "xxxxx cinquante euros xxxxx" },
];

const PdfView = ({ centerCity, fileDate, checkLine, contentLine }) => {
  const { currentSponsorListAsc } = useSelector((state) => state.sponsorList);
  const [top, setTop] = useState();

  const styles = StyleSheet.create({
    coreMessage: {
      position: "absolute",
      fontSize: "10px",
      left: "2.1cm",
      width: "17.61cm",
      height: "11.18cm",
      //top:  "5.2cm",
    },
    check: {
      fontSize: "10px",
      left: "2.1cm",
      position: "absolute",
     // top: "23.485cm",
      height: "5.5cm",
      width: "18cm",
    },
    logo: {
      position: "absolute",
      top: "-1.1cm",
      left: "-0.5cm",
      width: "8cm",
    },
  });
  return currentSponsorListAsc.coupons &&
    currentSponsorListAsc.coupons.length > 0 &&
    checkLine &&
    contentLine ? (
    <>
      <PDFViewer className="w-full h-full" showToolbar={true}>
        <Document className={{ backgrounColor: "black" }}>
          {currentSponsorListAsc.coupons.map((value, index) => {
            return (
              <Page
                size={"A4"}
                className="feuille"
                style={{ position: "relative", fontSize: "10px" }}
                key={index}
              >
                {/* <Image src={feuille} />
                <View style={styles.logo}><Image src={logopdf} /></View> */}
                <View
                  style={{
                    ...styles.coreMessage,
                    top: contentLine + "cm",
                  }}
                  fixed
                >
                  <Text style={{ left: "8.9cm", marginBottom: "0.3cm" }}>
                    {value.sponsor.gender +
                      " " +
                      value.sponsor.lastName +
                      " " +
                      value.sponsor.firstName}
                  </Text>
                  <Text style={{ left: "8.9cm" }}>{value.sponsor.address}</Text>
                  <Text style={{ left: "8.9cm" }}>
                    {value.sponsor.completeAddress}
                  </Text>
                  <Text style={{ left: "8.9cm", marginBottom: "1.59cm" }}>
                    {value.sponsor.zipCode}
                  </Text>
                  <Text style={{ marginBottom: "0.4cm" }}>Bonjour,</Text>
                  <Text style={{ marginBottom: "0.4cm" }}>
                    En parrainant l'un de vos proches ou collègues, qui a acheté
                    un équipement optique dans l'un de nos magasins, vous avez
                    validé un parrainage MANEO-OPTICIENS.{" "}
                  </Text>
                  <Text style={{ marginBottom: "0.4cm" }}>
                    J'ai donc le plaisir de vous adresser un chèque de{" "}
                    {value.amount} €.
                  </Text>
                  <Text style={{ marginBottom: "0.1cm" }}>
                    Je vous rappelle que le nombre de parrainage n'étant pas
                    limité, vous avez encore la possibilité de gagner de
                    l'argent, tout en faisant profiter vos proches de nos
                    offres.
                  </Text>
                  <Text style={{ marginBottom: "0.4cm" }}>
                    Vous trouverez ci-joint 2 nouvelles cartes de parrainages.
                  </Text>
                  <Text style={{ marginBottom: "1.4cm" }}>
                    Dans l'attente d'avoir le plaisir de vous croiser dans l'un
                    de nos magasins, je vous adresse mes sentiments les
                    meilleurs.
                  </Text>
                  <Text style={{ left: "11.3cm", marginBottom: "1.1cm" }}>
                    Cordialement,
                  </Text>
                  <Text style={{ left: "11.3cm" }}>Guillaume PEYROT</Text>
                  <Text style={{ left: "11.3cm" }}>
                    Directeur Général MANEO-OPTICIENS.
                  </Text>
                </View>
                <Image
                  src={Signature}
                  style={{ top: "17cm", left: "14cm", width: "4cm" }}
                ></Image>

                <View style={{ ...styles.check, top: checkLine + "cm" }}>
                  <Text
                    style={{
                      position: "absolute",
                      top: "0.5cm",
                      left: "4.6cm",
                      marginBottom: "0.4cm",
                      fontSize: "12px",
                    }}
                  >
                    {numberToText.find((n) => n.number === value.amount).text}
                  </Text>
                  <Text
                    style={{
                      position: "absolute",
                      left: "15cm",
                      top: "1.5cm",
                      fontSize: "12px",
                      // backgroundColor: "red",
                    }}
                  >
                    XXX {value.amount},00 € XX{" "}
                  </Text>
                  <Text
                    style={{
                      position: "absolute",
                      top: "1.6cm",
                      left: "2.3cm",
                      fontSize: "12px",
                    }}
                  >
                    {value.sponsor.lastName + " " + value.sponsor.firstName}
                  </Text>
                  <Text
                    style={{
                      position: "absolute",
                      top: "2.5cm",
                      left: "15.2cm",
                    }}
                  >
                    SAINT-LOUIS
                  </Text>
                  <Text
                    style={{
                      position: "absolute",
                      top: "3cm",
                      left: "15.3cm",
                    }}
                  >
                    {moment(fileDate).format("DD/MM/YYYY")}
                  </Text>
                  <Image
                    src={Signature}
                    style={{
                      position: "absolute",
                      top: "3.8cm",
                      left: "14.3cm",
                      width: "3cm",
                    }}
                  ></Image>
                </View>
              </Page>
            );
          })}
        </Document>
      </PDFViewer>
    </>
  ) : (
    <>
      <Loader />
    </>
  );
};

export default PdfView;
