import CenterCheckForm from "components/center/CenterCheckForm";
import { formatSelectCountries } from "components/functions/utils";
import Loader from "components/utils/Loader";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { centerApi } from "services/api/centerApi";
const countries = formatSelectCountries();

const Center = () => {
  const params = useParams();
  const [isNew, setIsNew] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [loader, setLoader] = useState(false);
  const [center, setCenter] = useState({
    name: "",
    zipCode: "",
    address: "",
    currentCheckBundle: null,
    newCurrentCheckBundle: null,
    checksBundle: [],
  });

  const [newMin, setNewMin] = useState(0);
  const [newMax, setNewMax] = useState(0);

  const handleChange = ({ currentTarget }) =>
    setCenter({ ...center, [currentTarget.name]: currentTarget.value });
  const handleSubmit = (event) => {
    event.preventDefault();
    if (isNew) {
    } else {
      update();
    }
  };
  const handleSelectChange = (event) =>
    setCenter({ ...center, zipCode: event.label });
  const handleCheckBundleChange = (event) => {
    const checkBundle = center.checksBundle.find(
      (c) => parseInt(c.id) === parseInt(event.value)
    );
    setCenter({ ...center, currentCheckBundle: checkBundle });
  };

  const fetchCenter = async () => {
    try {
      const result = await centerApi.getCenterById({ centerId: params.id });
      if (result.errors || !result.data) {
        toast.error("Erreur dans la récupération du centre");
        console.log(result.errors);
      } else {
        setCenter(result.data.getCenter);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const update = async () => {
    try {
      const result = await centerApi.updateCenter({
        center: {
          id: center.id,
          name: center.name,
          zipCode: center.zipCode,
          address: center.address,
          currentCheckBundle: center.currentCheckBundle.id,
        },
      });
      if (result.errors || !result.data) {
        toast.error("Erreur dans la récupération du centre");
        console.log(result.errors);
      } else {
        toast.success("Modification réussie");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const create = async () => {};

  useEffect(() => {
    setIsNew(isNaN(params.id));
    if (!isNaN(params.id)) {
      setLoader(true);
      fetchCenter();
      setLoader(false);
    }
  }, []);

  useEffect(() => {
    if (refresh) {
      setLoader(true);
      fetchCenter();
      setLoader(false);
      setRefresh(false);
    }
  }, [refresh]);

  return !loader ? (
    <>
      <div className=" mt-5 container mx-auto sm:px-6 lg:px-8">
        <form onSubmit={handleSubmit}>
          <div className="shadow sm:rounded-md">
            <div className="bg-white px-4 py-5 sm:p-6">
              <div className="mb-3">
                <h1 className="text-xl">
                  {isNew ? <>Nouveau centre</> : <>Modification centre</>}
                </h1>
              </div>
              <div className="grid grid-cols-6   gap-6">
                <div className="col-span-6">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Nom du centre
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={center.name}
                    onChange={handleChange}
                    autoComplete="given-name"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6">
                  <label
                    htmlFor="completeAdress"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Adresse complete du centre
                  </label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    value={center.address}
                    onChange={handleChange}
                    autoComplete="address"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                  />
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="zipCode"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Code postal
                  </label>
                  <Select
                    required
                    type="text"
                    name="zipCode"
                    id="zipCode"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                    onChange={(event) => handleSelectChange(event, "zipCode")}
                    value={{
                      value: countries.findIndex((c) => c === center.zipCode),
                      label: center.zipCode,
                    }}
                    options={countries.map((c, i) => {
                      return { value: i, label: c };
                    })}
                  />
                </div>
                <div className="col-span-6">
                  <div className="grid grid-cols-6 gap-6">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      N° chèque minimum
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={newMin}
                      onChange={(e) => setNewMin(Number(e.currentTarget.value))}
                      autoComplete="given-name"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                    />
                  </div>
                </div>
                {center.checksBundle && center.currentCheckBundle && (
                  <div className="col-span-6">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Lot en cours
                    </label>
                    <Select
                      required
                      type="text"
                      name="newCurrentCheckBundle"
                      id="newCurrentCheckBundle"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                      onChange={handleCheckBundleChange}
                      value={{
                        value: center.currentCheckBundle.id,
                        label: `min: ${center.currentCheckBundle.min} - max: ${center.currentCheckBundle.max}`,
                      }}
                      options={center.checksBundle.map((c, i) => {
                        return {
                          value: c.id,
                          label: `min: ${c.min} - max: ${c.max}`,
                        };
                      })}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
              {isNew && (
                <button
                  type="submit"
                  className="inline-flex justify-center rounded-md border border-transparent bg-green-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                  Enregistrer
                </button>
              )}
              {!isNew && (
                <button
                  type="submit"
                  className="inline-flex justify-center rounded-md border border-transparent bg-amber-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2"
                >
                  Modifier
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      {!isNew && (
        <>
          <CenterCheckForm centerId={center.id} setRefresh={setRefresh} />
        </>
      )}
    </>
  ) : (
    <div className="min-h-full">
      <Loader />
    </div>
  );
};

export default Center;
