import React from "react";
import CenterSelector from "../../../components/center/CenterSelector";
import CenterDateRangePicker from "../../../components/center/CenterDateRangePicker";
import SponsorStackedList from "../../../components/sponsor/list/SponsorStackedList";
import CenterDateTabs from "../../../components/center/CenterDateTabs";
import { useSelector } from "react-redux";
import ModalButtonCreate from "../../../components/sponsor/list/ModalButtonCreate";

const HomeSponsorList = () => {
  const { sponsorList } = useSelector((state) => state.sponsorList);
  const { current } = useSelector((state) => state.center);
  return (
    <>
      <div className=" mt-5 container mx-auto sm:px-6 lg:px-8">
        <CenterSelector />
        <div className="flex justify-between">
          <CenterDateRangePicker />
          <ModalButtonCreate centerId={parseInt(current.value)} />
        </div>

        <CenterDateTabs />
        <SponsorStackedList />
      </div>
    </>
  );
};

export default HomeSponsorList;
