import React from "react";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Logo from "../../assets/images/logomaneo.png";
import { useDispatch, useSelector } from "react-redux";
import { isValidToken } from "../../features/auth/authAction";
import { NavLink, useLocation } from "react-router-dom";
import { logout } from "../../features/auth/authSlice";

const navigation = [
  { name: "Tableau de bord", href: "/" },
  { name: "Parrainages", href: "/parrainages" },
  { name: "Centres", href: "/centres" },
  { name: "Configuration document", href: "/parametre-document"}
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DashBoardLayout = ({children}) => {
  const dispatch = useDispatch();
  const { userInfo, userToken } = useSelector((state) => state.auth);
  const location = useLocation();
  if (!userInfo) {
    dispatch(isValidToken(userToken));
  }

  const checkUrl = (current) => {
    let url = location.pathname.substring(1);
    if(current === "/" && url === "") {
      return true
    }else if(location.pathname.includes(current) && current !== "/"){ 
      return true
    }else { 
      return false
    }
  }
  return (
    <>
      <div className="flex min-h-full flex-col">
        <Disclosure as="nav" className="bg-white shadow-lg">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                <div className="relative flex h-16 items-center justify-between">
                  <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                    {/* Mobile menu button*/}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-maneo-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                    <div className="flex flex-shrink-0 items-center">
                      <img
                        className="block h-20 w-auto lg:hidden"
                        src={Logo}
                        alt="Your Company"
                      />
                      <img
                        className="hidden h-16 w-auto lg:block"
                        src={Logo}
                        alt="Your Company"
                      />
                    </div>
                    <div className="hidden sm:ml-6 sm:flex items-center">
                      <div className="flex space-x-4 items-center">
                        {navigation.map((item) => (
                          <NavLink
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              checkUrl(item.href)
                                ? "bg-maneo-700 text-white"
                                : "text-gray-900 hover:bg-maneo-500 hover:text-white",
                              "rounded-md px-3 py-2 text-sm font-medium"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            {item.name}
                          </NavLink>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                    {/* <button
                    type="button"
                    className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button> */}

                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                          <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                            <span className="text-sm font-medium leading-none text-white uppercase">
                              {userInfo.username && (
                                <> {userInfo.username.charAt(0) } </>
                              )}
                            </span>
                          </span>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={() => dispatch(logout())}
                                href="#"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Deconnexion
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden">
                <div className="space-y-1 px-2 pb-3 pt-2">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-maneo-500 hover:text-white",
                        "block rounded-md px-3 py-2 text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        {children}
      </div>
    </>
  );
};

export default DashBoardLayout;
