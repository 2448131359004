import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkAuth, logout } from "../../features/auth/authSlice";
import { api } from "../../services/api";
import { signIn } from "../../features/auth/authAction";
import { NavLink, Navigate } from "react-router-dom";
import ManeoLogo from "../../assets/images/logo-maneo-white.jpg"

const Login = () => {
  const dispatch = useDispatch();
  const { isAuth } = useSelector((state) => state.auth);
  const [credentials, setCredentials] = useState({
    login: "",
    password: "",
  });

  const handleChange = ({ currentTarget }) => {
    setCredentials({
      ...credentials,
      [currentTarget.name]: currentTarget.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(signIn(credentials));
  };
  return !isAuth ? (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-60 w-auto"
            src={ManeoLogo}
            alt="Maneo opticien"
          />
        </div>
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="login"
                className="block text-sm font-medium leading-6 text-maneo-500"
              >
                Identifiant
              </label>
              <div className="mt-2">
                <input
                  id="login"
                  name="login"
                  type="login"
                  onChange={handleChange}
                  required
                  className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-maneo-700 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-maneo-500"
                >
                  Mot de passe
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  onChange={handleChange}
                  autoComplete="current-password"
                  required
                  className="px-2  block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-maneo-700 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-maneo-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-maneo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-maneo-700"
              >
                Connexion
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  ) : (
    <>
      <Navigate to="/" />
    </>
  );
};

export default Login;
