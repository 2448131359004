import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../services/api";

export const getCenters = createAsyncThunk(
    "center/getCenters",
    async (_, thunkApi) => {
        const result = await api.centerApi.getAllCenter();
        if(result.errors || !result.data) {
            return thunkApi.abort(result.errors[0].message);
        } else {
            return result;
        }
    }
)