import { callApi } from ".";
import { SERVER_URL, headers } from "../../app/config";
import {
  CREATE_SPONSOR_LIST,
  GET_ALL_SPONSOR_LIST_BY_CENTER_AND_DATE,
  GET_SPONSOR_LIST,
} from "../graphql/sponsorlist";

const getSponsorListByCenter = async (data) => {
  try {
    return await callApi(
      SERVER_URL,
      GET_ALL_SPONSOR_LIST_BY_CENTER_AND_DATE,
      data,
      {
        ...headers,
        authorization: localStorage.getItem("token"),
      }
    );
  } catch (error) {
    console.log(error.message);
  }
};

const createSponsorList = async (data) => {
  try {
    return await callApi(
      SERVER_URL,
      CREATE_SPONSOR_LIST,
      data,
      {
        ...headers,
        authorization: localStorage.getItem("token"),
      }
    );
  } catch (error) {
    console.log(error.message);
  }
};

const getSponsorList = async (id) => {
  try {
    return await callApi(
      SERVER_URL,
      GET_SPONSOR_LIST,
      { sponsorListId: parseInt(id) },
      {
        ...headers,
        authorization: localStorage.getItem("token"),
      }
    );
  } catch (error) {
    console.log(error);
  }
};

export const sponsorListApi = { getSponsorListByCenter, getSponsorList, createSponsorList };
