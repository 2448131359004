export const UPDATE_SETTING = `#graphql
    mutation UpdateSettings($checkLine: Float, $contentLine: Float,) {
        updateSettings(checkLine: $checkLine, contentLine: $contentLine, ) {
            id
            checkLine
            contentLine
            userId   
    }
}
`;

export const GET_SETTING = `#graphql
    query GetSetting {
        getSetting {
            id
            checkLine
            contentLine
            userId
        }
    }
`;
