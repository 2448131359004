
const EnumStatus = [
    { value: "PENDING", label: "En attente"},
    { value: "REJECTED", label: "Rejeté"},
    { value: "RECEIVED", label: "Reçu"},
]

const getLabel = (status) => {
    const index = EnumStatus.findIndex(s => status === s.value) ?? "";
    
    return index !== -1 ? EnumStatus[index].label : "";
} 

export const SponsorCouponStatusEnum = { getLabel }