import { createSlice } from "@reduxjs/toolkit";
import { createSponsorList, getSponsorListByCenter, getSponsorListById } from "./sponsorListAction";

const initialState = {
  refresh: false,
  loading: false,
  currentSponsorList: null,
  currentSponsorListAsc: null,
  sponsorList: [],
};

export const sponsorListSlice = createSlice({
  name: "sponsorList",
  initialState,
  reducers: {
    updateRefresh(state, action){
      state.refresh = action.payload;
    },
    updateCurrentSponsorList(state, action){
      state.currentSponsorList = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSponsorListByCenter.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSponsorListByCenter.fulfilled, (state, action) => {
        state.sponsorList = action.payload.data.getSponsorListsbyCenter;
        state.loading = false;
        state.refresh = false;
      })
      .addCase(getSponsorListByCenter.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getSponsorListById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSponsorListById.fulfilled, (state, action) => {
        let current = action.payload.data.getSponsorList;
        let currentAsc = action.payload.data.getSponsorList;
        if(current.coupons && current.coupons.length > 0 && currentAsc.coupons && currentAsc.coupons.length > 0) {
          current.coupons.sort((a,b) => b.checkNumber - a.checkNumber);
          currentAsc.coupons.sort((a,b) => a.checkNumber - b.checkNumber);
        }
        state.currentSponsorList = current;
        state.currentSponsorListAsc = currentAsc;
        state.loading = false;
        state.refresh = false;
      })
      .addCase(getSponsorListById.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createSponsorList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createSponsorList.fulfilled, (state, action) => {
        let current = action.payload.data.getSponsorList;
        let currentAsc = action.payload.data.getSponsorList;
        if(current.coupons && current.coupons.length > 0 && currentAsc.coupons && currentAsc.coupons.length > 0) {
          current.coupons.sort((a,b) => b.checkNumber - a.checkNumber);
          currentAsc.coupons.sort((a,b) => a.checkNumber - b.checkNumber);
        }
        state.currentSponsorList = current;
        state.currentSponsorListAsc = currentAsc;
        state.loading = false;
        state.refresh = false;
      }); 
  },
});


export const { updateRefresh, updateCurrentSponsorList } = sponsorListSlice.actions;