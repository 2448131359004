import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { listYear } from "../../components/functions/utils";
moment.locale("fr");

const initDate = {
  first: moment().subtract(3, "month"),
  last: moment(),
};
const initialState = {
  endDate: initDate.last.format("YYYY-MM-DD"),
  startDate: initDate.first.format("YYYY-MM-DD"),
  listYear: listYear(initDate.first, initDate.last),
  selectedYear: initDate.last.format("YYYY")
};

export const periodSlice = createSlice({
  name: "period",
  initialState,
  reducers: {
    updateStartDate: (state, action) => {
      state.startDate = action.payload.startDate;
      state.listYear = listYear(
        moment(action.payload.startDate),
        moment(state.endDate)
      );
    },
    updateEndDate: (state, action) => {
      state.endDate = action.payload.endDate;
      state.listYear = listYear(
        moment(state.startDate),
        moment(action.payload.endDate)
      );
    },
    updateBoth: (state, action) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.selectedYear =  moment(action.payload.endDate, 'YYYY-MM-DD').format("YYYY");
      state.listYear = listYear(
        moment(action.payload.startDate),
        moment(action.payload.endDate)
      );
    },
    switchSelectedYear: (state, action) => {
      state.selectedYear = action.payload;
    }
  },
});

export const { updateEndDate, updateStartDate, updateBoth, switchSelectedYear } = periodSlice.actions;
