import { createSlice } from "@reduxjs/toolkit";
import { createOrUpdateCoupon } from "./sponsorCouponAction";

const initialState = {
  coupon: {
    sponsorListId: null,
    couponId: null,
    amount: 0,
    sponsor: {
      gender: "",
      firstName: "",
      lastName: "",
      address: "",
      completeAddress: "",
      zipCode: "",
    },
    customer: {
      gender: "",
      firstName: "",
      lastName: "",
      address: "",
      completeAddress: "",
      zipCode: "",
    },
  },
};

export const sponsorCouponSlice = createSlice({
  name: "sponsorCoupon",
  initialState,
  reducers: {
    updateCoupon: (state, action) => {
      state.coupon = action.payload;
    },
    initCoupon: (state) => {
      state.coupon = initialState.coupon;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrUpdateCoupon.pending, (state) => {
        state.loading = true;
      })
      .addCase(createOrUpdateCoupon.fulfilled, (state, action) => {
        state.coupon = initialState;
        state.loading = false;
      });
  },
});

export const { initCoupon, updateCoupon } = sponsorCouponSlice.actions;
