import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { switchSelectedYear } from "../../features/period/periodSlice";

const tabs = [
  { name: "My Account", href: "#", current: false },
  { name: "Company", href: "#", current: false },
  { name: "Team Members", href: "#", current: true },
  { name: "Billing", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const CenterDateTabs = () => {
  const { listYear, selectedYear } = useSelector((state) => state.period);
  const dispatch = useDispatch();

  return (
    listYear.length > 0 && (
      <div className="mt-5">
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-maneo-500 focus:outline-none focus:ring-maneo-500 sm:text-sm"
          >
            {listYear.map((tab, index) => (
              <option key={index}>{tab}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {listYear.map((tab, index) => (
                <button
                  key={index}
                  onClick={() => {
                    dispatch(switchSelectedYear(listYear[index]));
                  }}
                  className={classNames(
                    (tab.toString() === selectedYear.toString())
                      ? "border-maneo-500 text-maneo-500"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>
    )
  );
};

export default CenterDateTabs;
