import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./pages/auth/login";
import HomePage from "./pages/dashboard/home";
import UserRoute from "./routes/UserRoute";
import HomeSponsorList from "./pages/sponsor/list/HomeSponsorList";
import SponsorFile from "./pages/sponsor/list/SponsorFile";
import CenterList from "./pages/center/centerList";
import Center from "./pages/center/center";
import SettingDocument from "pages/setting/SettingDocument";

const App = () => {
  return (
    <Routes>
      <Route path="/connexion" element={<LoginPage />} />
      <Route element={<UserRoute />}>
        <Route path="/parametre-document" element={<SettingDocument />} />
        <Route path="/centres/:id" element={<Center />} />
        <Route path="/centres" element={<CenterList />} />
        <Route path="/parrainages/fichier/:id" element={<SponsorFile />} />
        <Route path="/parrainages" element={<HomeSponsorList />} />
        <Route path="/" element={<HomePage />} />
      </Route>
    </Routes>
  );
};

export default App;
