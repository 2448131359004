import CenterSelector from "components/center/CenterSelector";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { api } from "services/api";
import moment from "moment";
import { toast } from "react-toastify";
import { CSVDownload, CSVLink } from "react-csv";

const ExportCsv = ({}) => {
  const { endDate, startDate } = useSelector((state) => state.period);
  const { current } = useSelector((state) => state.center);
  const [disable, setDisable] = useState(false);
  const [exportList, setExportList] = useState([]);

  const getExport = async () => {
    setDisable(true);
    const result = await api.couponApi.exportCoupons({
      centerId: current.value,
      endDate: moment(endDate).format("YYYY-MM-DD"),
      startDate: moment(startDate).format("YYYY-MM-DD"),
    });
    if (result.errors || !result.data) {
      toast.error("Erreur lors de l'export.");
      console.log(result.errors);
    } else {
      let arrayCsv = [];
      result.data.getSponsorCoupons.map((data, i) => {
        arrayCsv.push([
          data.checkNumber,
          data.sponsor.lastName,
          data.sponsor.firstName,
          data.sponsor.address,
          data.sponsor.completeAddress,
          data.sponsor.zipCode,
          data.amount,
          moment(data.editionAt).format("DD/MM/YYYY"),
        ]);
      });
      setExportList([...arrayCsv]);
      setDisable(false);
    }
  };

  console.log(current);
  useEffect(() => {
    if(current?.value && endDate && startDate) {
        getExport();
    }
  }, [current, endDate, startDate])
  return (
    <>
      {exportList && exportList.length > 0 && (
        <>
          <CSVLink
            data={exportList}
            target="_blank"
            separator={";"}
            className="inline-flex items-center gap-x-1.5 rounded-md bg-maneo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-maneo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-maneo-500"
            filename={`export ${current.label} - ${moment(startDate).format("DDMMYYYY")} -  ${moment(endDate).format("DDMMYYYY")} `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m.75 12 3 3m0 0 3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
            </svg>
            Exporter
          </CSVLink>
        </>
      )}
    </>
  );
};

export default ExportCsv;
