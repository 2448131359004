import { callApi } from ".";
import { APP_API, SERVER_URL } from "../../app/config";
import { ADD_CHECK_BUNDLE, GET_CENTERS, GET_CENTER_BY_ID, UPDATE_CENTER } from "../graphql/center";

const headers = {
  "Content-Type": "application/json",
  app: APP_API,
};
const getAllCenter = async () => {
  return await callApi(
    SERVER_URL,
    GET_CENTERS,
    {},
    { ...headers, authorization: localStorage.getItem("token") }
  );
};


const getCenterById = async (data) => {
  return await callApi(
    SERVER_URL,
    GET_CENTER_BY_ID,
    data,
    { ...headers, authorization: localStorage.getItem("token") }
  );
};


const updateCenter = async (data) => {
  return await callApi(
    SERVER_URL,
    UPDATE_CENTER,
    data,
    { ...headers, authorization: localStorage.getItem("token") }
  );
}

const addNewCheckBundle = async (data)  => {
  return await callApi(
    SERVER_URL,
    ADD_CHECK_BUNDLE,
    data,
    { ...headers, authorization: localStorage.getItem("token") }
  );
}

export const centerApi = { getAllCenter, getCenterById, updateCenter, addNewCheckBundle };
