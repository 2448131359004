import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import OverlayPdf from "components/pdf/OverlayPdf";
import ModalUpdateCurrentCheck from "components/checkbundle/ModalUpdateCurrentCheck";
moment.locale("fr");

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SponsorListInfo = () => {
  const { currentSponsorList } = useSelector((state) => state.sponsorList);
  return (
    currentSponsorList && (
      <>
        <div className="flex  my-5 items-center justify-between gap-x-6 p-5 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
          <div className="min-w-0">
            <div className="flex items-start gap-x-3">
              <p className="text-sm font-semibold leading-6 text-gray-900">
                {currentSponsorList.name}
              </p>
              <p
                className={classNames(
                  currentSponsorList.isLocked
                    ? "text-red-700 bg-red-50 ring-red-600/20"
                    : "text-green-700 bg-green-50 ring-green-600/20",
                  "rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
                )}
              >
                {currentSponsorList.isLocked ? "Verouillé" : "Ouvert"}
              </p>
            </div>
            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
              <p className="whitespace-nowrap">
                Créé le {moment(currentSponsorList.createdAt).format("LL")}
              </p>
              <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                <circle cx={1} cy={1} r={1} />
              </svg>
              <p className="truncate">
                {" "}
                Publication le{" "}
                {moment(currentSponsorList.editionAt).format("LL")}
              </p>
            </div>
          </div>
          <div className="flex flex-none items-center gap-x-4">
            <OverlayPdf />
            {!currentSponsorList.isLocked && (
              <>
                <ModalUpdateCurrentCheck
                  centerId={currentSponsorList.center.id}
                />
              </>
            )}
          </div>
        </div>
      </>
    )
  );
};

export default SponsorListInfo;
