import { createSlice } from "@reduxjs/toolkit";
import { getCenters } from "./centerAction";
import { useDispatch } from "react-redux";
import { getSponsorListByCenter } from "../sponsor/list/sponsorListAction";

const initCenter = () => {
    const center = localStorage.getItem("center") ?? null;
    return center ? JSON.parse(center) : null;
};

const initialState = {
  loading: false,
  current: initCenter(),
  centerList: [],
  needRefresh: false
};


export const centerSlice = createSlice({
  name: "center",
  initialState,
  reducers: {
    switchCenter: (state, action) => {
      localStorage.setItem("center", JSON.stringify(action.payload));
      state.current = action.payload;
      state.needRefresh = true;
    },
    refreshCenter: (state, action) => {
      console.log(action.payload);
      state.needRefresh = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCenters.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCenters.fulfilled, (state, action) => {
        state.centerList = action.payload.data.getAllCenter;
        state.loading = false;
      });
  },
});

export const { switchCenter, refreshCenter } = centerSlice.actions;
