import { callApi } from ".";
import { SERVER_URL, headers } from "../../app/config";
import { CUSTOMERS, CUSTOMER_DETAILS } from "../graphql/customer";

const getCustomers = async () => {
  try {
    return await callApi(SERVER_URL, CUSTOMERS, {}, {
      ...headers,
      authorization: localStorage.getItem("token"),
    });
  } catch (error) {
    console.log(error.message);
  }
};

const getCustomerDetails = async (data) => {
  try {
    return await callApi(SERVER_URL, CUSTOMER_DETAILS, data, {
      ...headers,
      authorization: localStorage.getItem("token"),
    });
  } catch (error) {
    console.log(error.message);
  }
};

export const customerApi =  { getCustomers, getCustomerDetails }