import { callApi } from ".";
import { SERVER_URL, headers } from "../../app/config";
import { CREATE_OR_UPDATE_COUPON, EXPORT_SPONSOR_COUPONS } from "../graphql/sponsorcoupon";

const createOrUpdateCoupon = async (data) => {
  try {
    return await callApi(SERVER_URL, CREATE_OR_UPDATE_COUPON, data, {
      ...headers,
      authorization: localStorage.getItem("token"),
    });
  } catch (error) {
    console.log(error.message);
  }
};


const exportCoupons = async (data) => {
  try {
    return await callApi(SERVER_URL, EXPORT_SPONSOR_COUPONS, data, {
      ...headers,
      authorization: localStorage.getItem("token"),
    });
  } catch (error) {
    console.log(error.message);
  }
}


export const couponApi =  { createOrUpdateCoupon, exportCoupons }