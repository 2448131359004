import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { graphql } from "services/api";
import { GET_SETTING, UPDATE_SETTING } from "services/graphql/setting";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import PdfSimulator from "components/pdf/PdfSimulator";

const SettingDocument = (props) => {
  const [setting, setSetting] = useState({});
  const [isSaved, setIsSaved] = useState(true);

  const fetchSetting = async () => {
    try {
      const result = await graphql({}, GET_SETTING);
      if (result.errors || !result.data) {
        toast.error(
          "Une erreur est survenu dans la récupération de vos paramètre"
        );
        console.log(result.errors);
      } else {
        if(result.data.getSetting) {
            setSetting(result.data.getSetting);
        } else {
            setSetting({checkLine: 23.485, contentLine: 5.2})
        }
        
      }
    } catch (error) {
        console.log(error);
    }
  };

  const submitSetting = async () => {
    try {
      let checkLine = setting.checkLine;
      const contentLine = setting.contentLine;
      if (
        setting.checkLine.toString().indexOf(",") === -1 &&
        setting.contentLine.toString().indexOf(",") === -1 &&
        !isNaN(parseFloat(checkLine)) &&
        !isNaN(parseFloat(contentLine))
      ) {
        const result = await graphql(
          {
            checkLine: parseFloat(checkLine),
            contentLine: parseFloat(contentLine),
          },
          UPDATE_SETTING
        );
        if (result.errors || !result.data) {
          toast.error(
            "Une erreur est survenu dans l'enregistrement de vos paramètre"
          );
          console.log(result.errors);
        } else {
          setIsSaved(true);
          toast.success("enregristrement reussie !");
        }
      } else {
        toast.error("Erreur formatage des données transmises");
      }
    } catch (error) {
      console.log(error);
      toast.error("Erreur formatage des données transmises");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitSetting();
  };

  const handleChange = (event) => {
    setSetting({
      ...setting,
      [event.currentTarget.name]: event.currentTarget.value,
    });
    setIsSaved(false);
  };

  useEffect(() => {
    fetchSetting();
  }, []);

  console.log(setting);
  return (
    <>
      <div className=" mt-5 container mx-auto sm:px-6 lg:px-8">
        <form>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Hauteur contenu (par défaut 5.2)
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="contentLine"
                  id="contentLine"
                  onChange={handleChange}
                  value={setting?.contentLine ?? 5.2}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-maneo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Hauteur position chèque (par défaut 23.485)
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="checkLine"
                  id="chekLine"
                  onChange={handleChange}
                  value={setting?.checkLine ?? 23.485}
                  autoComplete="family-name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-maneo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div className="w-full mt-5">
            <div className="flex justify-between">
              {isSaved && setting && (
                <>
                  <PDFDownloadLink
                    document={
                      <PdfSimulator
                        contentLine={setting.contentLine}
                        checkLine={setting.checkLine}
                      />
                    }
                    fileName="fichier-test.pdf"
                    className="mr-2 inline-f gap-x-1.5 rounded-md bg-maneo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-maneo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-maneo-500"
                  >
                    {({ blob, url, loading, error }) =>
                      loading
                        ? "Chargement enc ours"
                        : "Générer document de test"
                    }
                  </PDFDownloadLink>
                </>
              )}
              {!isSaved && (
                <>
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    Enregistrer pour générer le fichier
                  </p>
                </>
              )}
              <button
                type="button"
                onClick={handleSubmit}
                className="mr-2 inline-flex items-center gap-x-1.5 rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500"
              >
                Enregistrer
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default SettingDocument;
